import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

import { Membership } from '@app/core/membership';
import { User } from '@app/shared/user';

import { AmazonUserProfile } from '../amazon-user-profile.service';
import { ClaimCodeValidationError } from '../prepaid-enrollment.service';
import { ClaimCodeInfo } from './expedited-registration.constants';
import { ExpeditedRegistrationErrors } from './expedited-registration.reducer';

export const expeditedRegistrationInitialize = createAction('[Expedited Registration] Initialize');

export const FetchAmazonUserProfileActions = createActionGroup({
  source: 'Expedited Registration',
  events: {
    'Fetch Amazon User Profile': props<{ sllt: string }>(),
    'Fetch Amazon User Profile Success': props<{ amazonUserProfile: AmazonUserProfile }>(),
    'Fetch Amazon User Profile Failure': emptyProps(),
  },
});

export const CreateAccountActions = createActionGroup({
  source: 'Expedited Registration',
  events: {
    'Create Account': emptyProps(),
    'Create Account Success': props<{ user: User; membership: Membership }>(),
    'Create Account Failure': props<{ error: ExpeditedRegistrationErrors }>(),
  },
});

export const ServiceAreaActions = createActionGroup({
  source: 'Expedited Registration',
  events: {
    'Service Area Selected': props<{ serviceAreaCode: string }>(),
    'Service Area Selection Success': props<{ user: User }>(),
    'Service Area Selection Failure': props<{ error: string }>(),
  },
});

export const TermsOfServiceActions = createActionGroup({
  source: 'Expedited Registration',
  events: {
    'Terms of Service Complete': emptyProps(),
    'Go Back': emptyProps(),
  },
});

export const ValidateClaimCodeActions = createActionGroup({
  source: 'Expedited Registration',
  events: {
    'Validate Claim Code': props<{ claimCode: string }>(),
    'Validate Claim Code Success': props<ClaimCodeInfo>(),
    'Validate Claim Code Failure': props<{ error: ClaimCodeValidationError }>(),
  },
});

export const MFAEnrollmentActions = createActionGroup({
  source: 'Expedited Registration',
  events: {
    'MFA Enrollment Initialized': emptyProps(),
    'MFA Enrollment Completed': emptyProps(),
  },
});

export const RegistrationRestartActions = createActionGroup({
  source: 'Expedited Registration',
  events: {
    'Registration Restart': props<{ user: User; membership: Membership }>(),
    'Registration Reset': emptyProps(),
    'Registration Continue': emptyProps(),
    'Registration Continue Set Claim Code Info': props<ClaimCodeInfo>(),
  },
});

export const ActivateMembershipActions = createActionGroup({
  source: 'Expedited Registration',
  events: {
    'Activate Membership': props<{ claimCode: string | null }>(),
    'Activate Membership Success': props<{ user: User; membership: Membership }>(),
    'Activate Membership Failure': props<{ error: ClaimCodeValidationError }>(),
  },
});

export const ExpeditedNoop = createActionGroup({
  source: 'Expedited Registration',
  events: {
    'Expedited Noop': emptyProps(),
  },
});
