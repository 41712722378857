import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import { BookAppointment, BookAppointmentVariables } from '@app/appointment/__generated__/BookAppointment';
import { upcomingAndCurrentAppointments } from '@app/home-redesign/home-screen-patient-info-graphql.fragments';

@Injectable({
  providedIn: 'root',
})
export class BookAppointmentGraphQLService extends Mutation<BookAppointment, BookAppointmentVariables> {
  document = gql`
    mutation BookAppointment($input: BookAppointmentInput!) {
      bookAppointment(input: $input) {
        success
        mutationErrors {
          path
          messages
        }
        appointment {
          id
          appointmentType {
            id
          }
        }
        patient {
          id
          upcomingAndCurrentAppointments {
            ...upcomingAndCurrentAppointments
          }
        }
      }
    }

    ${upcomingAndCurrentAppointments}
  `;
}
