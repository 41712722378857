<div *ngIf="inlineForm.showingForm" class="form-container" [class.navbar-hidden]="navbarHidden">
  <form
    class="mx-3 mx-lg-0 mx-xl-0 mb-lg-4 mb-xl-4"
    [name]="inlineForm.name"
    (ngSubmit)="onSubmit()"
    [formGroup]="inlineForm.formGroup"
  >
    <div class="content-container mb-lg-3 mb-xl-3">
      <ng-content></ng-content>

      <span *ngIf="inlineForm.formGroup.hasError('apiError')" class="error-message">
        <small>{{ inlineForm.formGroup.getError('apiError') }}</small>
      </span>
    </div>

    <div class="action-container mb-3">
      <hr class="mx-n3 d-lg-none d-xl-none" />

      <div class="d-flex d-lg-block d-xl-block justify-content-between">
        <button
          type="submit"
          class="btn btn-primary flex-fill"
          [disabled]="inlineForm.submitting || inlineForm.formGroup.invalid"
        >
          Save
        </button>
        <button
          *ngIf="inlineForm.canDelete"
          type="button"
          (click)="onDelete()"
          class="d-none d-lg-inline-block btn btn-outline-primary delete"
          [disabled]="inlineForm.submitting || inlineForm.formGroup.invalid"
          data-cy="inline-form-delete-button"
        >
          Delete
        </button>
        <button
          type="button"
          (click)="inlineForm.hideForm(); onCancel()"
          class="d-none d-lg-inline-block btn btn-link"
          [disabled]="inlineForm.submitting"
          data-cy="inline-form-cancel-button"
        >
          Cancel
        </button>

        <button
          *ngIf="inlineForm.canDelete"
          type="button"
          (click)="onDelete()"
          class="d-block d-lg-none flex-fill ms-1 btn btn-outline-primary delete"
          [disabled]="inlineForm.submitting || inlineForm.formGroup.invalid"
        >
          Delete
        </button>
        <button
          type="button"
          (click)="inlineForm.hideForm(); onCancel()"
          class="d-block d-lg-none flex-fill ms-1 btn btn-outline-primary"
          [disabled]="inlineForm.submitting"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
