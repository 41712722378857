import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of as observableOf } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { DocumentSigner } from '@app/core/legal-documents';
import { LinksService } from '@app/core/links.service';
import { Membership } from '@app/core/membership';
import { MembershipAction, MembershipService } from '@app/core/membership.service';
import { PrepaidEnrollmentMembershipService } from '@app/membership/prepaid-enrollment-membership.service';
import {
  PediatricMembershipTypes,
  PediatricRegistrationTypes,
} from '@app/registration/pediatric/pediatric-membership-type.service';
import { Coupon } from '@app/shared/coupon';
import { SendAppLinkGraphQLService } from '@app/shared/download-app-card/send-app-link-graphql.service';
import { HORNBILL_INVITE_SOURCE_FLOW_KEY, PrepaidInviteSourceFlow } from '@app/shared/hornbill-test-params';
import { ServiceArea } from '@app/shared/service-area';
import { ServiceAreaService } from '@app/shared/service-area.service';

import { AuthService } from '../core/auth.service';
import { UserService } from '../core/user.service';
import { User } from '../shared/user';
import { EnterpriseRegistration } from './enterprise/enterprise-registration';
import { MembershipType } from './enterprise/membership-type';
import {
  ChildRegCompleteProperties,
  RegInputEnteredProperties,
  RegistrationAnalyticsService,
} from './registration-analytics.service';
import { RegistrationService } from './registration.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationStateService {
  private static readonly EMAIL_VERIFICATION_QUESTION = 'email-verification';
  private static readonly MFA_STEP = 'mfa-step';

  patient: User;
  planId: number;
  currentQuestionIndex = 0;
  additionalStepsPostAccountCreation = 2;
  questions: string[];
  finalRegStepQuestionIndex = 0;
  allowBackButton = false;
  accountCreationQuestionIndex: number;
  regComplete = false;
  hasRegCompleteError = false;
  membershipType: PediatricMembershipTypes;
  pediatricRegType: PediatricRegistrationTypes;
  documentSigner: DocumentSigner;
  nonmemberRegistration = false;
  serviceAreas: ServiceArea[];
  textAppToPhone = false;
  isConsumerPromoAvailable = false;
  isFromEnterpriseRegistration = false;
  coupon: Coupon = null;
  prepaidClaimCode: string = null;
  hasAvailableInvites = false;
  canInviteMembers = false;
  prepaidEnrollmentEnabled: boolean;
  discountTrackingProperties;
  isPrepaidEnrollment = false;

  standardQuestions = [
    'first-name',
    'last-name',
    'email',
    'password',
    'service-area',
    'terms-of-service',
    'date-of-birth',
    'phone-number',
    'gender',
    'hearabout',
    'address',
  ];

  loggedOutPediatricQuestions = ['pediatric-service-area'];

  generalPediatricQuestions = [
    'child-intake',
    'guardian-confirmation',
    'child-account-details',
    'on-behalf-terms-of-service',
  ];

  consumerPediatricQuestions = [
    'child-intake',
    'guardian-confirmation',
    'child-account-details',
    'on-behalf-terms-of-service',
    'collect-payment-method',
  ];

  questionsAnalyticsModuleMap = {
    'pediatric-service-area': 'Service Area Page',
    child_intake: 'Child Info Page',
    'guardian-confirmation': 'Guardian Info Page',
    'child-account-details': 'Child Account Creation Page',
    'on-behalf-terms-of-service': 'Peds TOS Page',
    'collect-payment-method': 'Billing Page',
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private registrationAnalyticsService: RegistrationAnalyticsService,
    private registrationService: RegistrationService,
    private userService: UserService,
    private membershipService: MembershipService,
    private enterpriseRegistration: EnterpriseRegistration,
    private serviceAreaService: ServiceAreaService,
    private sendAppLinkGraphQLService: SendAppLinkGraphQLService,
    private featureFlagSelectors: FeatureFlagSelectors,
    private launchDarklyService: LaunchDarklyService,
    private linksService: LinksService,
    private prepaidEnrollmentMembershipService: PrepaidEnrollmentMembershipService,
  ) {
    this.questions = this.standardQuestions;
    this.accountCreationQuestionIndex = this.questions.indexOf('service-area');

    this.patient = new User();

    this.serviceAreaService.getServiceAreas(this.enterpriseRegistration.b2bCompanyId).subscribe(serviceAreas => {
      this.serviceAreas = serviceAreas;
      if (serviceAreas.length === 1) {
        this.enterpriseRegistration.setServiceArea(serviceAreas[0]);
        this.currentQuestionIndex = 1;
      }
    });

    this.launchDarklyService
      .featureFlag$(FeatureFlags.HORNBILL_ENROLLMENT_P0_ENABLED, false)
      .pipe(take(1))
      .subscribe((hornbillEnrollmentEnabled: boolean) => {
        this.prepaidEnrollmentEnabled = hornbillEnrollmentEnabled;
      });
  }

  get isConsumerMembership(): boolean {
    return this.membershipType.toUpperCase() === 'CONSUMER';
  }

  get isConsumerRegistration(): boolean {
    return this.pediatricRegType === PediatricRegistrationTypes.Consumer;
  }

  get needsPediatricClaimCode(): boolean {
    return (
      this.prepaidEnrollmentEnabled &&
      !this.prepaidClaimCode &&
      this.pediatricRegType === PediatricRegistrationTypes.PrepaidEnrollment &&
      this.hasAvailableInvites
    );
  }

  regInputEntered(props: Partial<RegInputEnteredProperties>) {
    const propsWithMemType = {
      ...props,
      membershipType: this.membershipType,
    } as RegInputEnteredProperties;
    this.registrationAnalyticsService.regInputEntered(propsWithMemType);
  }

  nextQuestion() {
    this.currentQuestionIndex++;
    this.updateAllowBackButton();
    // Note that we need to separate the concepts of reg complete (when the user is a member, usually before MFA)
    // and post reg steps, what happens after the post-reg steps have completed
    if (this.currentQuestionIndex === this.finalRegStepQuestionIndex + 1) {
      this.markRegComplete();
    }
    if (this.currentQuestionIndex === this.questions.length) {
      this.processPostRegSteps();
    }
  }

  previousQuestion() {
    if (this.allowBackButton && (this.currentQuestionIndex === 0 || this.canSkipServiceAreaQuestion())) {
      window.history.back();
    } else {
      this.currentQuestionIndex--;
      this.updateAllowBackButton();
    }
  }

  setAllowBackButton(value: boolean) {
    this.allowBackButton = value;
  }

  /*
    Prevents users from going back if on first question or any question after account creation
                      ▼ account creation step [4]
    | 0 | 1 | 2 | 3 | 4 | 5 | 6 |
    | n | y | y | y | y | n | n |
  */
  private updateAllowBackButton() {
    const onFirstQuestion = this.currentQuestionIndex === 0 || this.canSkipServiceAreaQuestion();
    const onQuestionAfterAcctCreation = this.currentQuestionIndex > this.accountCreationQuestionIndex;

    this.allowBackButton = !onFirstQuestion && !onQuestionAfterAcctCreation && !this.regComplete;
  }

  get currentQuestion() {
    return this.questions[this.currentQuestionIndex];
  }

  get lastQuestionWasEmailVerification() {
    return this.questions[this.questions.length - 1] === RegistrationStateService.EMAIL_VERIFICATION_QUESTION;
  }

  get onLastQuestion(): boolean {
    return this.currentQuestionIndex + 1 === this.questions.length;
  }

  get progress() {
    return (
      (100 * (this.currentQuestionIndex + 1)) /
      (this.accountCreationQuestionIndex + this.additionalStepsPostAccountCreation)
    );
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  initializeRegisteringOnBehalfWithRegType(
    registrationType: PediatricRegistrationTypes,
    verifyEmail = false,
    registrationPedsMfaEnabled = false,
    pedsDsuMfaEnabled = false,
    prepaidClaimCode: string | null = null,
  ) {
    this.setupRegistrationWithType(registrationType, prepaidClaimCode);
    this.completeRegSetupWithType(verifyEmail, registrationPedsMfaEnabled, pedsDsuMfaEnabled);
  }

  // TODO: Remove when hornbill-enrollment-p0-enabled is removed
  initializeRegisteringOnBehalf(
    isEnterprise: boolean,
    verifyEmail = false,
    registrationPedsMfaEnabled = false,
    pedsDsuMfaEnabled = false,
  ) {
    this.setupRegistrationType(isEnterprise);
    this.completeRegSetupWithType(verifyEmail, registrationPedsMfaEnabled, pedsDsuMfaEnabled);
  }

  private completeRegSetupWithType(
    verifyEmail: boolean,
    registrationPedsMfaEnabled: boolean,
    pedsDsuMfaEnabled: boolean,
  ) {
    this.regComplete = false;
    this.setLoggedInStateAttributes();
    this.setFinalRegStepQuestionIndex();
    this.addPostRegQuestions(verifyEmail, registrationPedsMfaEnabled, pedsDsuMfaEnabled);

    this.accountCreationQuestionIndex = this.questions.indexOf('child-account-details');
  }

  private setLoggedInStateAttributes() {
    if (this.authService.isLoggedIn()) {
      this.setLoggedInRegistrationStateAttributes();
    } else {
      this.setLoggedOutRegistrationStateAttributes();
    }
  }

  private addPostRegQuestions(verifyEmail: boolean, registrationPedsMfaEnabled: boolean, pedsDsuMfaEnabled: boolean) {
    const mfaEnabledForPediatricFlow = !this.authService.isLoggedIn() ? registrationPedsMfaEnabled : pedsDsuMfaEnabled;
    if (mfaEnabledForPediatricFlow) {
      this.questions = [...this.questions, RegistrationStateService.MFA_STEP];
      this.additionalStepsPostAccountCreation++;
    }
    if (verifyEmail) {
      this.questions = [...this.questions, RegistrationStateService.EMAIL_VERIFICATION_QUESTION];
      this.additionalStepsPostAccountCreation++;
    }
  }

  createPediatricUser() {
    return this.authService.isLoggedIn()
      ? this.userService.createPediatricUserForPatient(this.patient, this.planId, this.prepaidClaimCode)
      : this.createPediatricUserForNonmember();
  }

  /* Completely resets the reg state. Clears entered data, progress, etc.  */
  resetState() {
    this.currentQuestionIndex = this.serviceAreas.length === 1 ? 1 : 0;
  }

  private createPediatricUserForNonmember() {
    if (this.enterpriseRegistration.b2bCompanyId) {
      return this.userService.createPediatricUserForEnterpriseNonmember(
        this.patient,
        this.patient.enterpriseRegistrationDetails,
      );
    } else if (this.prepaidClaimCode) {
      return this.userService.createPediatricUserForPrepaidNonmember(this.patient, this.prepaidClaimCode);
    } else {
      return this.userService.createPediatricUserForConsumerNonmember(this.patient);
    }
  }

  private processPostRegSteps() {
    this.isChildMemberManagerWithInvites()
      .pipe(take(1))
      .subscribe({
        next: (isChildMemberManagerWithSeats: boolean) => {
          if (this.startedInInviteFlow || isChildMemberManagerWithSeats) {
            this.redirectToPrepaidInviteFlow(isChildMemberManagerWithSeats);
          } else {
            this.redirectToChildRegComplete();
          }
          if (this.textAppToPhone) {
            this.textAppLinkToPhone();
          }
        },
        error: () => {
          this.redirectToChildRegComplete();
        },
      });
  }

  private markRegComplete() {
    this.updateWhenAmazonMembership()
      .pipe(
        take(1),
        switchMap(() => this.registrationService.markRegComplete()),
      )
      .subscribe({
        next: () => {
          this.regComplete = true;
          if (this.membershipType !== PediatricMembershipTypes.Enterprise) {
            this.sendRegCompleteAnalytics();
          }
        },
        error: () => {
          this.hasRegCompleteError = true;
        },
      });
  }

  private sendRegCompleteAnalytics() {
    const mpParams: ChildRegCompleteProperties = {
      membershipType: this.membershipType,
      module: this.getMixpanelModuleFromQuestion(this.questions[this.finalRegStepQuestionIndex]),
      isLoggedIn: this.authService.isLoggedIn(),
      isPrepaidEnrollment: this.isPrepaidEnrollment && this.prepaidEnrollmentEnabled,
      serviceArea: this.patient?.serviceArea?.name,
    };
    this.registrationAnalyticsService.trackChildRegCompleted(mpParams);
  }

  private updateWhenAmazonMembership() {
    if (this.prepaidEnrollmentEnabled && this.pediatricRegType === PediatricRegistrationTypes.PrepaidEnrollment) {
      return this.membershipService.updatePediatricAmazonMembership$(
        this.patient,
        this.prepaidClaimCode,
        MembershipAction.New,
      );
    }

    return observableOf(null);
  }

  private isChildMemberManagerWithInvites() {
    if (
      this.prepaidEnrollmentEnabled &&
      this.pediatricRegType === PediatricRegistrationTypes.PrepaidEnrollment &&
      this.nonmemberRegistration
    ) {
      return this.prepaidEnrollmentMembershipService.canInviteMembers$();
    }

    return observableOf(false);
  }

  private get startedInInviteFlow() {
    return (
      this.prepaidEnrollmentEnabled &&
      this.canInviteMembers &&
      !!this.activatedRoute.snapshot.queryParams[HORNBILL_INVITE_SOURCE_FLOW_KEY]
    );
  }

  private textAppLinkToPhone() {
    this.sendAppLinkGraphQLService.mutate({ input: { phoneNumber: this.patient.phoneNumber } }).subscribe();
  }

  private redirectToChildRegComplete() {
    if (this.coupon) {
      this.router.navigate(['/registration/child-registration-complete'], {
        queryParams: { discount_code: this.coupon.id },
      });
    } else {
      this.router.navigate(['/registration/child-registration-complete']);
    }
  }

  private redirectToPrepaidInviteFlow(addQueryParamSource: boolean) {
    if (
      this.activatedRoute.snapshot.queryParams[HORNBILL_INVITE_SOURCE_FLOW_KEY] ===
      PrepaidInviteSourceFlow.MembershipSettings
    ) {
      this.router.navigate([this.linksService.membershipSettings]);
    } else {
      this.router.navigate([this.linksService.prepaidInvite], {
        queryParams: {
          ...this.activatedRoute.snapshot.queryParams,
          ...(addQueryParamSource && { sourceFlow: PrepaidInviteSourceFlow.Registration }),
        },
      });
    }
  }

  // TODO:BHX: Remove when hornbill-enrollment-p0-enabled is removed
  private setupRegistrationType(enterprise: boolean) {
    if (enterprise) {
      this.setupEnterprisePediatricRegistration();
    } else {
      this.setupConsumerPediatricRegistration();
    }
  }

  private setupRegistrationWithType(registrationType: PediatricRegistrationTypes, prepaidClaimCode: string | null) {
    if (registrationType === PediatricRegistrationTypes.Enterprise) {
      this.setupEnterprisePediatricRegistration();
    } else if (registrationType === PediatricRegistrationTypes.PrepaidEnrollment) {
      this.setupPrepaidEnrollmentRegistration(prepaidClaimCode);
    } else {
      this.setupConsumerPediatricRegistration();
    }
  }

  private setLoggedInRegistrationStateAttributes() {
    this.userService.getUser();
    this.membershipService.getMembership();
    this.nonmemberRegistration = false;
    combineLatest([this.membershipService.membership$, this.userService.user$])
      .pipe(take(1))
      .subscribe(([membership, user]: [Membership, User]) => {
        this.patient.serviceArea = user.serviceArea;
        if (membership.isB2b()) {
          this.planId = membership.planId;
        }
        this.hasAvailableInvites = membership.canInviteAdditionalSeatHolders();
        this.canInviteMembers = membership.isGroupMembershipManager() && membership.hasMultipleSeats();
      });
  }

  private setFinalRegStepQuestionIndex() {
    this.finalRegStepQuestionIndex = this.questions.length - 1;
  }

  private setLoggedOutRegistrationStateAttributes() {
    this.nonmemberRegistration = true;
    this.questions = this.loggedOutPediatricQuestions.concat(this.questions);
  }

  private setupEnterprisePediatricRegistration() {
    const startedInEnterpriseReg = this.enterpriseRegistration.details.membershipType === MembershipType.KIDS;
    this.patient = startedInEnterpriseReg ? this.enterpriseRegistration.patient : new User();

    this.questions = this.generalPediatricQuestions;
    this.membershipType = PediatricMembershipTypes.Enterprise;
    this.pediatricRegType = PediatricRegistrationTypes.Enterprise;
  }

  private setupPrepaidEnrollmentRegistration(prepaidClaimCode: string | null) {
    this.patient = new User();

    this.questions = this.generalPediatricQuestions;
    this.membershipType = PediatricMembershipTypes.Consumer;
    this.pediatricRegType = PediatricRegistrationTypes.PrepaidEnrollment;
    this.isPrepaidEnrollment = true;
    this.prepaidClaimCode = prepaidClaimCode;
  }

  private setupConsumerPediatricRegistration() {
    this.patient = new User();

    this.questions = this.consumerPediatricQuestions;
    this.membershipType = PediatricMembershipTypes.Consumer;
    this.pediatricRegType = PediatricRegistrationTypes.Consumer;
    this.additionalStepsPostAccountCreation = 3;
  }

  private canSkipServiceAreaQuestion() {
    return this.currentQuestionIndex === 1 && this.serviceAreas.length === 1;
  }

  private getMixpanelModuleFromQuestion(question: string) {
    let module = null;
    if (this.questionsAnalyticsModuleMap[question] !== undefined) {
      module = this.questionsAnalyticsModuleMap[question];
    }

    return module;
  }
}
