import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, finalize, map, take, tap } from 'rxjs/operators';

import { AutoEnrollMfaInput, MfaFactor } from '@app/__generated__/graphql.types';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { LinksService } from '@app/core/links.service';
import { MP_EVENT_PAGE_VIEWED, MFA_REGISTRATION_ERROR_PAGE, MFA_REGISTRATION_PAGE } from '@app/core/mixpanel.constants';
import { UserService } from '@app/core/user.service';
import { BannerType } from '@app/omgui/omgui-banner/omgui-banner.component';
import { AutoEnrollMfaGraphQLService } from '@app/shared/mfa-step/auto-enroll-mfa.graphql.service';
import { MfaAnalyticsService } from '@app/shared/mfa-step/mfa-analytics.service';
import { OmguiButtonVariant } from '@omgui/omgui-button/omgui-button.component';

import { RadioOptionConfig } from '../radio-group/radio-group.component';

@Component({
  selector: 'mfa-reg-step',
  templateUrl: 'mfa-reg-step.component.html',
})
export class MfaRegStepComponent implements OnInit {
  @Input() pediatricUser: { phoneNumber: string; email: string } | undefined;
  @Output() submit = new EventEmitter<void>();

  readonly ButtonVariant = OmguiButtonVariant;
  readonly BannerType = BannerType;
  formGroup: FormGroup;
  phoneNumberFormControlName = 'phoneNumber';
  phoneNumberFormErrorMessage: string;
  emailFormControlName = 'email';
  smsVoicePreferencesFormControlName = 'smsVoicePreference';
  inputValidationClass = 'text-danger mt-2 mb-1 d-block';
  mfaError = false;
  phoneNumber: string;
  isLoading = true;
  mfaChannelOptions: RadioOptionConfig[] = [];
  preferredAuthenticationMethod = MfaFactor.Sms;
  isMfaFactorPreferenceEnabled$: Observable<boolean>;
  isRegistrationMfaEmailEnabled$: Observable<boolean>;

  constructor(
    public linksService: LinksService,
    private userService: UserService,
    private autoEnrollMfaGraphQLService: AutoEnrollMfaGraphQLService,
    private analyticsService: MfaAnalyticsService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    const phoneNumberControl = new FormControl({ value: undefined, disabled: false }, [Validators.required]);
    const emailControl = new FormControl({ value: undefined, disabled: false }, [Validators.required]);
    const smsVoicePreferencesFormControl = new FormControl({ value: undefined, disabled: false }, [
      Validators.required,
    ]);

    this.formGroup = new FormGroup({
      [this.phoneNumberFormControlName]: phoneNumberControl,
      [this.emailFormControlName]: emailControl,
      [this.smsVoicePreferencesFormControlName]: smsVoicePreferencesFormControl,
    });

    this.isMfaFactorPreferenceEnabled$ = this.launchDarklyService.featureFlag$(
      FeatureFlags.REGISTRATION_MFA_SMS_VOICE_PREFERENCE,
      false,
    );

    this.isRegistrationMfaEmailEnabled$ = this.launchDarklyService.featureFlag$(
      FeatureFlags.REGISTRATION_MFA_BACKUP_EMAIL,
      false,
    );

    this.userService
      .getUser()
      .pipe(take(1))
      .subscribe(user => {
        this.phoneNumber = this.pediatricUser ? this.pediatricUser.phoneNumber : user.phoneNumber;
        this.formGroup.controls[this.phoneNumberFormControlName].patchValue(this.phoneNumber);
        this.formGroup.controls[this.emailFormControlName].patchValue(user.preferredEmail);
        this.isLoading = false;
        this.analyticsService.trackMfaEvent(MP_EVENT_PAGE_VIEWED, MFA_REGISTRATION_PAGE);
      });

    this.mfaChannelOptions = [
      {
        label: 'Text message',
        onClick: () => this.setPreferredAuthenticationMethod(MfaFactor.Sms),
        formControlName: this.smsVoicePreferencesFormControlName,
        value: MfaFactor.Sms,
        checked: () => this.preferredAuthenticationMethod === MfaFactor.Sms,
      },
      {
        label: 'Phone call',
        onClick: () => this.setPreferredAuthenticationMethod(MfaFactor.Voice),
        formControlName: this.smsVoicePreferencesFormControlName,
        value: MfaFactor.Voice,
        checked: () => this.preferredAuthenticationMethod === MfaFactor.Voice,
      },
    ];
  }

  setPreferredAuthenticationMethod(authenticationMethod: MfaFactor) {
    this.preferredAuthenticationMethod = authenticationMethod;
  }

  onSubmit() {
    this.analyticsService.trackMfaEvent('MFA Registration Error Confirmed', MFA_REGISTRATION_ERROR_PAGE);
    this.submit.emit();
  }

  enableMfaAutomatically() {
    this.isLoading = true;
    const payload: AutoEnrollMfaInput = {
      phoneNumber: this.formGroup.value[this.phoneNumberFormControlName],
      pediatricEmail: this.pediatricUser ? this.pediatricUser.email : undefined,
      authFactor: this.preferredAuthenticationMethod,
      email: this.formGroup.value[this.emailFormControlName],
    };

    this.autoEnrollMfaGraphQLService
      .mutate({ input: payload })
      .pipe(
        map(result => !!result.data.autoEnrollMfa?.success),
        tap(mfaEnrolled => {
          if (!mfaEnrolled) {
            this.setErrorState();
            return;
          }
          this.analyticsService.trackMfaEvent('MFA Registration Confirmed', MFA_REGISTRATION_PAGE);
          this.analyticsService.updateMFAProperties({ mfaEnabled: true });
          this.submit.emit();
        }),
        catchError(() => {
          this.setErrorState();
          return observableOf(false);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  setErrorMessage(errorMessage: string): void {
    this.phoneNumberFormErrorMessage = errorMessage;
  }

  setErrorState() {
    this.mfaError = true;
    this.analyticsService.trackMfaEvent(MP_EVENT_PAGE_VIEWED, MFA_REGISTRATION_ERROR_PAGE);
  }

  isInvalidEmail() {
    const email = this.formGroup.controls[this.emailFormControlName];
    return email.invalid;
  }
}
