import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { OmguiCloseXModule } from '@app/omgui/omgui-close-x/omgui-close-x.component';
import { WindowService } from '@app/utils/window.service';

@Component({
  selector: 'om-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [trigger('fadeInOut', [state('void', style({ opacity: 0 })), transition('void <=> *', animate(200))])],
  standalone: true,
  imports: [CommonModule, OmguiCloseXModule],
})
export class ToastComponent {
  static timeoutMs = 6000;

  message: string;
  visible = false;
  private timeout: number;

  constructor(private windowService: WindowService) {}

  show(message: string): void {
    this.message = message;
    this.visible = true;

    this.timeout = this.windowService.setTimeout(() => {
      this.close();
    }, ToastComponent.timeoutMs);
  }

  close() {
    this.visible = false;
    this.message = undefined;

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }
}
