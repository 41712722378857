import { Component, EventEmitter, Input, Output } from '@angular/core';

import { RegistrationAnalyticsService } from '@app/registration/registration-analytics.service';

@Component({
  selector: 'om-registration-restart',
  templateUrl: './registration-restart.component.html',
  styleUrls: ['./registration-restart.component.scss'],
})
export class RegistrationRestartComponent {
  @Input() firstName: string;

  @Output() registerReset = new EventEmitter();
  @Output() registerContinue = new EventEmitter();

  constructor(private registrationAnalyticsService: RegistrationAnalyticsService) {}

  continueRegistration() {
    this.registerContinue.emit();
  }

  registerAsNewUser() {
    this.registerReset.emit();
  }
}
