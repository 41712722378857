import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import {
  MODULE_ACCOUNT_CREATION_PAGE,
  MODULE_ADDRESS_PAGE,
  MODULE_BILLING,
  MODULE_EMAIL_VERIFICATION_PAGE,
  MODULE_LEGAL_DOCUMENT_PAGE,
  MODULE_PHONE_DOB_SEX_PAGE,
  MODULE_REGISTRATION_CONFIRMATION_PAGE,
  MODULE_REGISTRATION_POSTPONEMENT_PAGE,
  MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
  MP_EVENT_FREE_TRIAL_REG_SUCCESSFULLY_SUBMITTED,
  MP_EVENT_GIFT_REG_SUCCESSFULLY_SUBMITTED,
  MP_EVENT_REF_REG_SUCCESSFULLY_SUBMITTED,
  MP_EVENT_FAMILY_SUCCESSFULLY_SUBMITTED,
  MP_FLOW_REGISTRATION,
} from '@app/core/mixpanel.constants';

export enum RegisterSteps {
  BasicInfo = 0,
  Location = 1,
  PersonalInfo = 2,
  TermsOfService = 3,
  Payment = 4,
  EmailVerification = 5,
  Confirmation = 6,
  Restart = 7,
  ValuePropsSurvey = 8,
  BasicInfoPasswordPage = 9,
  RegistrationBlocked = 10,
  Mfa = 11,
}

export const REGISTER_STEPS_ORDER: RegisterSteps[] = [
  RegisterSteps.ValuePropsSurvey, // Behind a feature flag
  RegisterSteps.BasicInfo,
  RegisterSteps.Location,
  RegisterSteps.RegistrationBlocked, // Behind a feature flag
  RegisterSteps.PersonalInfo,
  RegisterSteps.TermsOfService,
  RegisterSteps.Payment,
  RegisterSteps.Mfa,
  RegisterSteps.EmailVerification,
  RegisterSteps.Confirmation,
];

// Consecutive flagged steps not yet functioning
export const REGISTER_STEPS_FEATURE_FLAG_MAP: Partial<Record<RegisterSteps, FeatureFlags>> = {
  [RegisterSteps.ValuePropsSurvey]: FeatureFlags.CONSUMER_REGISTRATION_VALUE_PROPS_INTERSTITIAL,
  [RegisterSteps.RegistrationBlocked]: FeatureFlags.CONSUMER_REGISTRATION_SERVICE_AREA_CAPACITY_BLOCK,
  [RegisterSteps.Mfa]: FeatureFlags.REGISTRATION_MFA_CONSUMER,
};

export const REGISTER_STEPS_FEATURE_FLAG_QUERY_PARAM_RESTRICTIONS: Partial<Record<RegisterSteps, string[]>> = {
  [RegisterSteps.ValuePropsSurvey]: ['ref', 'hp-hero-cta'], // only consider showing this step with `?ref=hp-hero-cta` in the url
};

export const STEPS_BACK_BUTTON_HIDDEN: RegisterSteps[] = [
  RegisterSteps.BasicInfo,
  RegisterSteps.Confirmation,
  RegisterSteps.EmailVerification,
  RegisterSteps.Location,
  RegisterSteps.RegistrationBlocked,
  RegisterSteps.ValuePropsSurvey,
];

export const LD_PAGE_VIEWED_EVENTS: Record<RegisterSteps, string> = {
  [RegisterSteps.ValuePropsSurvey]: 'Consumer Registration Value Props Survey Page Viewed',
  [RegisterSteps.BasicInfo]: 'Consumer Registration Basic Info Page Viewed',
  [RegisterSteps.BasicInfoPasswordPage]: 'Consumer Registration Basic Info Password Page Viewed',
  [RegisterSteps.Location]: 'Consumer Registration Address Page Viewed',
  [RegisterSteps.PersonalInfo]: 'Consumer Registration Personal Info Page Viewed',
  [RegisterSteps.TermsOfService]: 'Consumer Registration Terms of Service Page Viewed',
  [RegisterSteps.Payment]: 'Consumer Registration Payment Page Viewed',
  [RegisterSteps.EmailVerification]: 'Consumer Registration Email Verification Page Viewed',
  [RegisterSteps.Confirmation]: 'Consumer Registration Confirmation Page Viewed',
  [RegisterSteps.Restart]: 'Consumer Registration Restart Page Viewed',
  [RegisterSteps.RegistrationBlocked]: 'Consumer Registration Registration Blocked Page Viewed',
  [RegisterSteps.Mfa]: 'Consumer Registration MFA Page Viewed',
};

export const ACCOUNT_LOCKED_MODULE_VARIANT = 'Account Locked Error State Page';

export const REGISTER_MP_FLOW_VERSION = 'cons_reg_2020';
export const PREPAID_REGISTER_MP_FLOW_VERSION = 'hornbill_registration_2023';

export const REGISTER_GA_PAGE_VIEW_EVENT = 'page view';

export const LD_BASIC_INFO_SUBMITTED = 'Consumer Registration Basic Info Page Submitted';
export const LD_PAYMENT_SUCCESS = 'Consumer Registration Successfully Paid';
export const LD_PAYMENT_SUCCESS_APPLE_PAY = 'Consumer Registration Successfully Paid with Apple Pay';
export const LD_PAYMENT_SUCCESS_AFTERPAY = 'Consumer Registration Successfully Paid with Afterpay';
export const LD_PAYMENT_SUCCESS_TEST = 'Consumer Registration Test Successfully Paid Event';

export enum RegisterFlows {
  Paid = 'Paid',
  FreeTrial = 'FreeTrial',
  Gifting = 'Gifting',
  Referral = 'Referral',
  Family = 'Family',
  Prepaid = 'Prepaid',
}

export enum RegisterDiscountTypes {
  FreeTrial = 'FreeTrial',
  Regular = 'Regular',
  Referral = 'Referral',
  Enterprise = 'Enterprise',
  Gift = 'Gift',
}

export const RegisterAnalyticsConfig: Record<string, Record<string, string>> = {
  Paid: {
    BasicInfo_mp_module: MODULE_ACCOUNT_CREATION_PAGE,
    EmailVerification_mp_module: MODULE_EMAIL_VERIFICATION_PAGE,
    Location_mp_module: MODULE_ADDRESS_PAGE,
    PersonalInfo_mp_module: MODULE_PHONE_DOB_SEX_PAGE,
    TermsOfService_mp_module: MODULE_LEGAL_DOCUMENT_PAGE,
    Payment_mp_module: MODULE_BILLING,
    Confirmation_mp_module: MODULE_REGISTRATION_CONFIRMATION_PAGE,
    RegistrationBlocked_mp_module: MODULE_REGISTRATION_POSTPONEMENT_PAGE,
    ga_category: 'ConSignupForm',
    registration_success_mp_event: MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
    flow: MP_FLOW_REGISTRATION,
    flow_version: REGISTER_MP_FLOW_VERSION,
    post_reg_mp_flow: 'Post Registration',
    Restart_mp_module: 'Restart Registration Page',
    ValuePropsSurvey_mp_module: 'Value Props Survey Page',
    membership_subscription_type: null,
    is_membership_manager: null,
    subscription_id: null,
    membership_billing_cycle: null,
  },
  FreeTrial: {
    BasicInfo_mp_module: `Free Trial ${MODULE_ACCOUNT_CREATION_PAGE}`,
    EmailVerification_mp_module: MODULE_EMAIL_VERIFICATION_PAGE,
    Location_mp_module: MODULE_ADDRESS_PAGE,
    PersonalInfo_mp_module: MODULE_PHONE_DOB_SEX_PAGE,
    TermsOfService_mp_module: MODULE_LEGAL_DOCUMENT_PAGE,
    Payment_mp_module: MODULE_BILLING,
    Confirmation_mp_module: MODULE_REGISTRATION_CONFIRMATION_PAGE,
    RegistrationBlocked_mp_module: MODULE_REGISTRATION_POSTPONEMENT_PAGE,
    ga_category: 'FreeTrialSignupForm',
    registration_success_mp_event: MP_EVENT_FREE_TRIAL_REG_SUCCESSFULLY_SUBMITTED,
    flow: MP_FLOW_REGISTRATION,
    flow_version: REGISTER_MP_FLOW_VERSION,
    post_reg_mp_flow: 'Post Registration',
    Restart_mp_module: 'Restart Registration Page',
    ValuePropsSurvey_mp_module: 'Value Props Survey Page',
    membership_subscription_type: null,
    is_membership_manager: null,
    subscription_id: null,
    membership_billing_cycle: null,
  },
  Gifting: {
    BasicInfo_mp_module: `Gift ${MODULE_ACCOUNT_CREATION_PAGE}`,
    EmailVerification_mp_module: MODULE_EMAIL_VERIFICATION_PAGE,
    Location_mp_module: MODULE_ADDRESS_PAGE,
    PersonalInfo_mp_module: MODULE_PHONE_DOB_SEX_PAGE,
    TermsOfService_mp_module: MODULE_LEGAL_DOCUMENT_PAGE,
    Payment_mp_module: MODULE_BILLING,
    Confirmation_mp_module: MODULE_REGISTRATION_CONFIRMATION_PAGE,
    RegistrationBlocked_mp_module: MODULE_REGISTRATION_POSTPONEMENT_PAGE,
    ga_category: 'GiftingSignupForm',
    registration_success_mp_event: MP_EVENT_GIFT_REG_SUCCESSFULLY_SUBMITTED,
    flow: 'Gift Registration',
    flow_version: REGISTER_MP_FLOW_VERSION,
    post_reg_mp_flow: 'Gift Registration',
    Restart_mp_module: 'Restart Registration Page',
    ValuePropsSurvey_mp_module: 'Value Props Survey Page',
    membership_subscription_type: null,
    is_membership_manager: null,
    subscription_id: null,
    membership_billing_cycle: null,
  },
  Referral: {
    BasicInfo_mp_module: 'Account Creation Page',
    EmailVerification_mp_module: MODULE_EMAIL_VERIFICATION_PAGE,
    Location_mp_module: MODULE_ADDRESS_PAGE,
    PersonalInfo_mp_module: MODULE_PHONE_DOB_SEX_PAGE,
    TermsOfService_mp_module: MODULE_LEGAL_DOCUMENT_PAGE,
    Payment_mp_module: MODULE_BILLING,
    Confirmation_mp_module: MODULE_REGISTRATION_CONFIRMATION_PAGE,
    RegistrationBlocked_mp_module: MODULE_REGISTRATION_POSTPONEMENT_PAGE,
    ga_category: 'ReferralSignupForm',
    registration_success_mp_event: MP_EVENT_REF_REG_SUCCESSFULLY_SUBMITTED,
    flow: MP_FLOW_REGISTRATION,
    flow_version: REGISTER_MP_FLOW_VERSION,
    post_reg_mp_flow: 'Post Registration',
    Restart_mp_module: 'Restart Registration Page',
    ValuePropsSurvey_mp_module: 'Value Props Survey Page',
    membership_subscription_type: null,
    is_membership_manager: null,
    subscription_id: null,
    membership_billing_cycle: null,
  },
  Family: {
    BasicInfo_mp_module: 'Account Creation Page',
    EmailVerification_mp_module: MODULE_EMAIL_VERIFICATION_PAGE,
    Location_mp_module: MODULE_ADDRESS_PAGE,
    PersonalInfo_mp_module: MODULE_PHONE_DOB_SEX_PAGE,
    TermsOfService_mp_module: MODULE_LEGAL_DOCUMENT_PAGE,
    Payment_mp_module: MODULE_BILLING,
    Confirmation_mp_module: MODULE_REGISTRATION_CONFIRMATION_PAGE,
    RegistrationBlocked_mp_module: MODULE_REGISTRATION_POSTPONEMENT_PAGE,
    ga_category: 'ConSignupForm',
    registration_success_mp_event: MP_EVENT_FAMILY_SUCCESSFULLY_SUBMITTED,
    flow: 'Family Registration',
    flow_version: REGISTER_MP_FLOW_VERSION,
    post_reg_mp_flow: 'Post Registration',
    Restart_mp_module: 'Restart Registration Page',
    ValuePropsSurvey_mp_module: 'Value Props Survey Page',
    membership_subscription_type: null,
    is_membership_manager: null,
    subscription_id: null,
    membership_billing_cycle: null,
  },
  Prepaid: {
    BasicInfo_mp_module: MODULE_ACCOUNT_CREATION_PAGE,
    EmailVerification_mp_module: MODULE_EMAIL_VERIFICATION_PAGE,
    Location_mp_module: MODULE_ADDRESS_PAGE,
    PersonalInfo_mp_module: MODULE_PHONE_DOB_SEX_PAGE,
    TermsOfService_mp_module: MODULE_LEGAL_DOCUMENT_PAGE,
    Payment_mp_module: MODULE_BILLING,
    Confirmation_mp_module: MODULE_REGISTRATION_CONFIRMATION_PAGE,
    RegistrationBlocked_mp_module: MODULE_REGISTRATION_POSTPONEMENT_PAGE,
    ga_category: 'ConSignupForm',
    registration_success_mp_event: MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
    flow: MP_FLOW_REGISTRATION,
    flow_version: PREPAID_REGISTER_MP_FLOW_VERSION,
    post_reg_mp_flow: 'Post Registration',
    Restart_mp_module: 'Restart Registration Page',
    ValuePropsSurvey_mp_module: 'Value Props Survey Page',
    membership_subscription_type: 'Bundle', // Bundle | Individual
    is_membership_manager: 'false', // true | false
    subscription_id: null,
    membership_billing_cycle: null, // Monthly | Annual
  },
};

export const MEMBERSHIP_BENEFITS = [
  '24/7 on-demand care with Video Chat at no extra cost',
  'Messaging with your provider in the app',
  'Easy booking of same/next-day appointments in the app',
  'In app prescription requests and renewals',
  'Experts to help navigate the healthcare maze',
  'Online access to your health summaries and care plans',
];

export const MEMBERSHIP_BENEFITS_VIRTUAL = [
  '24/7 on-demand care via Video Chat and Treat Me Now at no extra cost',
  'Message with our providers',
  'Manage your prescriptions in the app',
  'Book in-person visits at any One Medical location in the app',
];
