export const HORNBILL_CLAIM_CODE_PARAM_KEY = 'claim_code';
export const HORNBILL_ANALYTICS_CLAIM_CODE_PARAM_KEY = 'claimCode';
export const HORNBILL_ANALYTICS_ERROR_SOURCE_PARAM_KEY = 'errorSource';

export const HORNBILL_INVITE_SOURCE_FLOW_KEY = 'sourceFlow';
export enum PrepaidInviteSourceFlow {
  Registration = 'registration',
  MembershipSettings = 'membershipSettings',
  Conversion = 'c',
}

// Guard Path Test Strings
export const HORNBILL_GUARD_PATH_TEST_HOME = '/';
export const HORNBILL_GUARD_PATH_TEST_CONSUMER_REG = '/register';
export const HORNBILL_GUARD_PATH_TEST_REG_FAMILY = '/registration/family';
export const HORNBILL_GUARD_PATH_TEST_REG_PLANS = '/registration/plans';
export const HORNBILL_GUARD_PATH_TEST_LANDING_PAGE = '/registration/paid';
export const HORNBILL_GUARD_PATH_TEST_CONVERSION = '/registration/paid/conversion';

// Links
export const HORNBILL_MANAGE_ON_AMAZON_URL = 'https://health.amazon.com/prime/onemedical/settings';
