import * as fromRouter from '@ngrx/router-store';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  createFormGroupState,
  wrapReducerWithFormStateUpdate,
  createFormStateReducerWithUpdate,
  FormGroupState,
  onNgrxForms,
  setValue,
  updateGroup,
} from 'ngrx-forms';

import { selectRouter } from '@app/app.reducer';
import { Membership } from '@app/core/membership';
import { AmazonUserProfileEncryptedPayload } from '@app/registration/amazon-user-profile.service';
import {
  ClaimCodeInfo,
  ExpeditedRegistrationSteps,
} from '@app/registration/expedited/expedited-registration.constants';
import { User } from '@app/shared/user';

import {
  ExpeditedAccountCreationInfo,
  expeditedAccountCreationInfoFormState,
  expeditedAccountCreationInfoFormGroupValidation,
} from './expedited-account-creation.reducer';
import {
  ActivateMembershipActions,
  CreateAccountActions,
  FetchAmazonUserProfileActions,
  ServiceAreaActions,
  ValidateClaimCodeActions,
  RegistrationRestartActions,
  TermsOfServiceActions,
} from './expedited-registration.actions';

export const FORM_ID = '(Expedited) Expedited Registration Form';

export interface ExpeditedRegistrationForm {
  expeditedAccountCreationInfo: ExpeditedAccountCreationInfo;
}

export const initialFormState = createFormGroupState<ExpeditedRegistrationForm>(FORM_ID, {
  expeditedAccountCreationInfo: expeditedAccountCreationInfoFormState,
});

export enum ExpeditedRegistrationErrors {
  None,
  SlltExchangeFailed,
  ValidationError, // Account creation error 422 with an error message
  InternalServerError, // Account creation catch all error
}

export interface ExpeditedRegistrationState {
  form: FormGroupState<ExpeditedRegistrationForm>; // The main form
  errorMessage: string | null; // Account creation form errors
  currentStep: ExpeditedRegistrationSteps; // Which registration step we are currently on
  isEmailTaken: boolean; // Is the email address taken? (Should this be in the form?)
  isSubmitting: boolean; // Is submission in progress
  validating: boolean; // Is the form being validated
  firstName: string | null;
  encryptedPayload: AmazonUserProfileEncryptedPayload;
  claimCodeInfo: ClaimCodeInfo;
  user: User;
  membership: Membership;
  error: ExpeditedRegistrationErrors;
}

export const initialState: ExpeditedRegistrationState = {
  form: initialFormState,
  errorMessage: null,
  currentStep: null,
  firstName: null,
  isEmailTaken: false,
  isSubmitting: false,
  validating: false,
  encryptedPayload: null,
  claimCodeInfo: null,
  user: null,
  membership: null,
  error: ExpeditedRegistrationErrors.None,
};

export const validateAndUpdateFormState = updateGroup<ExpeditedRegistrationForm>({
  expeditedAccountCreationInfo: expeditedAccountCreationInfoFormGroupValidation,
});

export const formReducer = createFormStateReducerWithUpdate<ExpeditedRegistrationForm>(validateAndUpdateFormState);

export const ExpeditedRegistrationReducer = createReducer(
  initialState,
  onNgrxForms(),

  on(FetchAmazonUserProfileActions.fetchAmazonUserProfileSuccess, (state, action) => {
    const nextState = {
      ...state,
      encryptedPayload: action.amazonUserProfile.encryptedPayload,
      error: ExpeditedRegistrationErrors.None,
    };
    // merge the amazon profile into the current state
    nextState.form = formReducer(
      setValue(state.form, {
        ...state.form.value,
        // applying the merge
        expeditedAccountCreationInfo: {
          ...state.form.value.expeditedAccountCreationInfo,
          ...action.amazonUserProfile,
        },
      }),
      action,
    );
    return nextState;
  }),

  on(FetchAmazonUserProfileActions.fetchAmazonUserProfileFailure, (state, {}) => ({
    ...state,
    error: ExpeditedRegistrationErrors.SlltExchangeFailed,
  })),

  on(FetchAmazonUserProfileActions.fetchAmazonUserProfile, (state, {}) => ({
    ...state,
    error: ExpeditedRegistrationErrors.None,
  })),

  on(
    ValidateClaimCodeActions.validateClaimCodeSuccess,
    (state, { isMembershipManager, seatsAvailable, subscriptionId, billingCycle }) => ({
      ...state,
      currentStep: ExpeditedRegistrationSteps.CreateAccount,
      claimCodeInfo: {
        isMembershipManager,
        seatsAvailable,
        subscriptionId,
        billingCycle,
      },
    }),
  ),

  on(CreateAccountActions.createAccount, state => ({
    ...state,
    isSubmitting: true,
  })),

  on(CreateAccountActions.createAccountSuccess, (state, action) => ({
    ...state,
    isSubmitting: false,
    currentStep: ExpeditedRegistrationSteps.ServiceArea,
    user: action.user,
    membership: action.membership,
    error: ExpeditedRegistrationErrors.None,
  })),

  on(CreateAccountActions.createAccountFailure, (state, action) => ({
    ...state,
    error: action.error,
    isSubmitting: false,
    currentStep: ExpeditedRegistrationErrors.ValidationError === action.error ? null : state.currentStep,
  })),

  on(ActivateMembershipActions.activateMembershipSuccess, (state, action) => ({
    ...state,
    currentStep: ExpeditedRegistrationSteps.MFAEnrollment,
    user: action.user,
    membership: action.membership,
    error: ExpeditedRegistrationErrors.None,
  })),

  on(ServiceAreaActions.serviceAreaSelected, state => ({
    ...state,
    isSubmitting: true,
  })),

  on(ServiceAreaActions.serviceAreaSelectionFailure, (state, action) => ({
    ...state,
    errorMessage: action.error,
    isSubmitting: false,
  })),

  on(ServiceAreaActions.serviceAreaSelectionSuccess, (state, action) => ({
    ...state,
    isSubmitting: false,
    currentStep: ExpeditedRegistrationSteps.TermsOfService,
    user: action.user,
    errorMessage: null,
    error: ExpeditedRegistrationErrors.None,
  })),

  on(TermsOfServiceActions.goBack, state => ({
    ...state,
    currentStep: ExpeditedRegistrationSteps.ServiceArea,
  })),

  on(RegistrationRestartActions.registrationRestart, (state, action) => ({
    ...state,
    currentStep: ExpeditedRegistrationSteps.Restart,
    user: action.user,
    membership: action.membership,
  })),

  on(RegistrationRestartActions.registrationContinue, state => {
    let stepToResume = ExpeditedRegistrationSteps.ServiceArea;

    if (state.user.serviceArea) {
      stepToResume = ExpeditedRegistrationSteps.TermsOfService;
    }

    return {
      ...state,
      currentStep: stepToResume,
    };
  }),

  on(RegistrationRestartActions.registrationReset, _state => ({
    ...initialState,
  })),

  on(RegistrationRestartActions.registrationContinueSetClaimCodeInfo, (state, action) => {
    const { isMembershipManager, seatsAvailable, subscriptionId, billingCycle } = action;
    const nextState = {
      ...state,
      claimCodeInfo: {
        isMembershipManager,
        seatsAvailable,
        subscriptionId,
        billingCycle,
      },
    };
    return nextState;
  }),
);

const wrappedReducer = wrapReducerWithFormStateUpdate(
  ExpeditedRegistrationReducer,
  state => state.form,
  validateAndUpdateFormState,
);

export function expeditedReducer(state: ExpeditedRegistrationState | undefined, action: Action) {
  return wrappedReducer(state, action);
}

export const selectExpeditedState = createFeatureSelector<ExpeditedRegistrationState>('expedited');

export const { selectQueryParam, selectQueryParams } = fromRouter.getSelectors(selectRouter);

export const getAllQueryParams = selectQueryParams;
export const getSlltQueryParam = selectQueryParam('sllt');
export const getClaimCodeQueryParam = selectQueryParam('claim_code');

export const getForm = createSelector(
  selectExpeditedState,
  (state: ExpeditedRegistrationState) => state.form as FormGroupState<ExpeditedRegistrationForm>,
);

export const getAccountCreationInfoForm = createSelector(
  selectExpeditedState,
  (state: ExpeditedRegistrationState) =>
    state.form.controls.expeditedAccountCreationInfo as FormGroupState<ExpeditedAccountCreationInfo>,
);

export const getCurrentStep = createSelector(
  selectExpeditedState,
  (state: ExpeditedRegistrationState) => state.currentStep,
);

export const getErrorMessage = createSelector(
  selectExpeditedState,
  (state: ExpeditedRegistrationState) => state.errorMessage,
);

export const getIsEmailTaken = createSelector(
  selectExpeditedState,
  (state: ExpeditedRegistrationState) => state.isEmailTaken,
);

export const getIsSubmitting = createSelector(
  selectExpeditedState,
  (state: ExpeditedRegistrationState) => state.isSubmitting,
);

export const getIsValidating = createSelector(
  selectExpeditedState,
  (state: ExpeditedRegistrationState) => state.validating,
);

export const getClaimCodeInfo = createSelector(
  selectExpeditedState,
  (state: ExpeditedRegistrationState) => state.claimCodeInfo,
);

export const getAmazonEncryptedPayload = createSelector(
  selectExpeditedState,
  (state: ExpeditedRegistrationState) => state.encryptedPayload,
);

export const getUser = createSelector(selectExpeditedState, (state: ExpeditedRegistrationState) => state.user);

export const getMembership = createSelector(
  selectExpeditedState,
  (state: ExpeditedRegistrationState) => state.membership,
);

export const getDropOffClaimCode = createSelector(selectExpeditedState, (state: ExpeditedRegistrationState) =>
  state.membership ? state.membership.dropOffClaimCode : null,
);

export const getError = createSelector(selectExpeditedState, (state: ExpeditedRegistrationState) => state.error);
