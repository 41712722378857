<div class="modal-header p-2 border-0">
  <omgui-close-x
    *ngIf="showCloseButton"
    role="button"
    class="btn close fw-light text-end"
    aria-label="Close"
    (click)="close.emit()"
    data-cy="modal-close-button"
  ></omgui-close-x>
  <div *ngIf="!showCloseButton" class="m-3"></div>
</div>
<div class="d-md-block d-sm-none d-none text-center pb-4"><ng-content></ng-content></div>
<div *ngIf="!hideModalBody" class="mx-md-5" [ngClass]="{ 'pb-4': hidePrimaryCta }">
  <div class="modal-body px-4 pb-4 px-md-0 pt-0 pb-md-4 text-center">
    <h2 [ngClass]="{ 'mb-4': hidePrimaryCta }">{{ heading }}</h2>
    <p class="fixed-size mb-0" [innerHTML]="subheader"></p>
  </div>
</div>
<div *ngIf="!hidePrimaryCta" [class]="primaryClasses" [ngClass]="secondaryCallToActionLabel ? 'pb-md-1' : 'pb-md-5'">
  <div class="col-md-12 col-lg-12 col-sm-12 px-0" data-cy="modal-primary-cta">
    <button
      (click)="primaryCallToAction.emit()"
      class="btn btn-primary btn-block text-center"
      data-cy="modal-primary-cta-button"
    >
      {{ primaryCallToActionLabel }}
    </button>
  </div>
</div>
<div *ngIf="secondaryCallToActionLabel" class="modal-body text-center pt-0 py-3">
  <button (click)="secondaryCallToAction.emit()" class="btn btn-link" data-cy="modal-secondary-cta-button">
    {{ secondaryCallToActionLabel }}
  </button>
</div>
<ng-content select="[custom]"></ng-content>
