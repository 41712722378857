import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { differenceInDays, formatRelative, parseISO, subDays } from 'date-fns';
import { enUS } from 'date-fns/locale';

import {
  AppointmentBookingState,
  AppointmentBookingStateService,
} from '@app/appointment/appointment-booking-state-service';
import { AppointmentRoutingStateService } from '@app/appointment/appointment-routing-state-service/appointment-routing-state.service';
import { AppointmentSearchState } from '@app/appointment/appointment-search-service/appointment-search-state';
import { AppointmentSearchStateService } from '@app/appointment/appointment-search-service/appointment-search-state.service';
import { AppointmentSearchService } from '@app/appointment/appointment-search.service';
import { AppointmentType } from '@app/appointment/appointment-type';
import {
  AppointmentInventory,
  DayInventories,
  firstInventoryDateAvailable,
  getFirstDateInventories,
} from '@app/appointment/provider-inventories';
import { AppointmentAnalyticsBaseService } from '@app/core/appointment-analytics-base.service';
import { ServiceAreasDataService } from '@app/service-areas/service-areas-data.service';
import { formatDate } from '@app/shared/date-format.pipe';
import { Office } from '@app/shared/office';
import { OfficeService } from '@app/shared/office.service';
import { Provider } from '@app/shared/provider';
import { ServiceArea } from '@app/shared/service-area';
import { ComponentSize } from '@omgui/component-size';
import { ProfileBubbleStates } from '@omgui/omgui-profile-bubble/omgui-profile-bubble.component';

import { OmguiBadgeSize } from '../omgui/omgui-badge/omgui-badge.component';

const NEW_MEMBER_BOOKING_REASON = `New member's first visit to discuss health goals and concerns.`;
const SEARCH_STATE_DEFAULT_DAYS = 3;
@Component({
  selector: 'om-next-remote-visit',
  templateUrl: './next-remote-visit.component.html',
  styleUrls: ['./next-remote-visit.component.scss'],
})
export class NextRemoteVisitComponent implements OnInit {
  @Input() provider: Provider;
  @Input() office: Office;
  @Input() inventory: DayInventories;
  @Input() appointmentType: AppointmentType;
  @Input() biography?: string;
  @Input() analyticsService: AppointmentAnalyticsBaseService;
  @Input() size: 'default' | 'narrow' | 'big' = 'default';
  @Input() badgeSize: OmguiBadgeSize = OmguiBadgeSize.MEDIUM;
  @ViewChild('appointmentConfirmationModal', { static: true }) appointmentConfirmationModal: TemplateRef<unknown>;

  photoSize = ComponentSize.medium;
  profileBubbleState: ProfileBubbleStates = ProfileBubbleStates.REMOTE;
  firstDateAvailable: string;
  badgeText: string;
  firstAvailableDayText: string;
  firstDaysFirstFiveInventorySlots: AppointmentInventory[];
  selectedInventory: AppointmentInventory = null;
  bookingState: AppointmentBookingState;
  bookingStateAppointmentType: AppointmentType;
  seeMoreBiographySelected = false;
  serviceArea?: ServiceArea;

  private photoSizeMap: Record<string, ComponentSize> = {
    narrow: ComponentSize.mediumSmall,
    big: ComponentSize.extraExtraLarge,
    default: ComponentSize.medium,
  };

  private modalRef: NgbModalRef;
  searchState: AppointmentSearchState;

  constructor(
    protected bookingStateService: AppointmentBookingStateService,
    private modalService: NgbModal,
    private officeService: OfficeService,
    private routingStateService: AppointmentRoutingStateService,
    private searchService: AppointmentSearchService,
    private searchStateService: AppointmentSearchStateService,
    private serviceAreasService: ServiceAreasDataService,
  ) {}

  ngOnInit(): void {
    this.firstDateAvailable = firstInventoryDateAvailable(this.inventory);
    this.firstAvailableDayText = formatDate(this.firstDateAvailable, 'MMM d');
    this.firstDaysFirstFiveInventorySlots = this.inventory[this.firstDateAvailable].slice(0, 5);
    this.badgeText = this.getBadgeText(this.firstDateAvailable);
    this.photoSize = this.getPhotoSize();
    this.searchState = this.searchStateService.getSearchState();
    this.bookingState = this.bookingStateService.getAppointmentBookingState();
    this.biography = this.removeHtmlTags(this.biography);
    this.bookingState.setSelectedServiceArea(this.bookingState.getSelectedServiceArea() ?? this.office.serviceArea);
    this.serviceArea = this.bookingState.getSelectedServiceArea();
    if (!this.serviceArea) {
      this.setServiceArea();
    }
  }

  // This code is meant to set this.serviceArea if it is not defined in this.bookingState.
  // Since this component is used both inside and outside of the appointment booking flow,
  // this.bookingState may not be initialized, and will not have a set serviceArea.
  // The OM Api's are also inconsistent. Some REST api's return a serviceAreaId for an office object
  // while others do not.
  private setServiceArea() {
    if (this.office.serviceArea) {
      this.serviceArea = ServiceArea.fromGraphQL({
        ...this.office.serviceArea,
        id: this.office.serviceArea.id.toString(),
      });
    } else if (this.office.serviceAreaId) {
      this.serviceAreasService
        .getById(this.office.serviceAreaId)
        .subscribe((serviceArea: ServiceArea) => (this.serviceArea = serviceArea));
    } else {
      // fetch office by API then fetch service area;
      this.officeService.getOffice(this.office.id).subscribe((office: Office) => {
        this.serviceAreasService
          .getById(office.serviceAreaId)
          .subscribe((serviceArea: ServiceArea) => (this.serviceArea = serviceArea));
      });
    }
  }

  private getBadgeText(date: string): string {
    const now = new Date();
    const nextAvailableDate = parseISO(date);
    const yesterday = subDays(now, 1);
    const dayDiff = differenceInDays(nextAvailableDate, yesterday);
    const options = {
      today: "'Available Today'",
      tomorrow: "'Available Tomorrow'",
      yesterday: "''",
      lastWeek: "''",
      nextWeek: "'Available in '" + dayDiff + "' days'",
      other: "'Available in '" + dayDiff + "' days'",
    };

    const customEnUS: Locale = {
      ...enUS,
      formatRelative: token => options[token],
    };

    return formatRelative(nextAvailableDate, now, { locale: customEnUS });
  }

  selectAppointment(inventory: AppointmentInventory) {
    this.selectedInventory = inventory;
    this.overrideAppointmentType();
    this.overrideAppointmentReason();
    this.bookingStateService.setIsRecommendedRemoteAppointment(true);
    this.analyticsService.inventorySelected(inventory, {
      serviceArea: this.serviceArea?.name,
      isRemoteRecommendation: true,
      appointmentType: this.bookingState.appointmentType.displayName,
      appointmentTypeId: this.bookingState.appointmentType.id,
    });
    this.openAppointmentConfirmationModal();
  }

  remoteSearch() {
    const inventoryLength = getFirstDateInventories(this.inventory).length;
    this.analyticsService.seeMoreAppointmentsClicked(inventoryLength, { serviceArea: this.serviceArea?.name });
    this.overrideAppointmentType();

    if (!!this.searchState) {
      this.searchService.getResults(this.searchState);
    } else {
      this.overrideAppointmentReason();
      this.bookingStateService.setAppointmentBookingState(this.bookingState);
      this.searchStateService.initSearchState(this.bookingState, SEARCH_STATE_DEFAULT_DAYS);
      this.searchState = this.searchStateService.getSearchState();

      this.routingStateService.resetAppointmentRoutingState();
      this.routingStateService.appointmentRoutingState.appointmentTypeId = this.appointmentType.id;
      this.routingStateService.appointmentRoutingState.appointmentTypeLocked = true;
      this.routingStateService.appointmentRoutingState.reasonForVisit = NEW_MEMBER_BOOKING_REASON;
      this.routingStateService.appointmentRoutingState.serviceAreaId = this.serviceArea.id;

      this.routingStateService.start$();
    }
  }

  openAppointmentConfirmationModal() {
    this.modalRef = this.modalService.open(this.appointmentConfirmationModal, { size: 'lg' });
  }

  overrideAppointmentType() {
    this.bookingStateAppointmentType = this.bookingState.appointmentType;
    this.bookingState.setSelectedAppointmentType(this.appointmentType, false);
  }

  overrideAppointmentReason() {
    const reason = this.bookingState.reason;
    if (reason && reason.length > 0) {
      return;
    } else {
      this.bookingState.reason = NEW_MEMBER_BOOKING_REASON;
    }
  }

  deselectAppointment() {
    this.selectedInventory = null;
    this.bookingState.setSelectedAppointmentType(this.bookingStateAppointmentType, false);
    this.modalRef.close();
  }

  displayFullBiography() {
    this.seeMoreBiographySelected = true;
  }

  displayLessBiography() {
    this.seeMoreBiographySelected = false;
  }

  private removeHtmlTags(text: string): string {
    return text?.replace(/<[^>]*>?/gm, '');
  }

  private getPhotoSize(): ComponentSize {
    return !!this.photoSizeMap[this.size?.toString()]
      ? this.photoSizeMap[this.size.toString()]
      : this.photoSizeMap.default;
  }
}
