import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import {
  RescheduleAppointment,
  RescheduleAppointmentVariables,
} from '@app/appointment/__generated__/RescheduleAppointment';
import { upcomingAndCurrentAppointments } from '@app/home-redesign/home-screen-patient-info-graphql.fragments';

@Injectable({
  providedIn: 'root',
})
export class RescheduleAppointmentGraphQLService extends Mutation<
  RescheduleAppointment,
  RescheduleAppointmentVariables
> {
  document = gql`
    mutation RescheduleAppointment($input: RescheduleAppointmentInput!) {
      rescheduleAppointment(input: $input) {
        success
        mutationErrors {
          path
          messages
        }
        appointment {
          id
          appointmentType {
            id
          }
        }
        patient {
          id
          upcomingAndCurrentAppointments {
            ...upcomingAndCurrentAppointments
          }
        }
      }
    }

    ${upcomingAndCurrentAppointments}
  `;
}
