import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Params, RouterModule } from '@angular/router';

import { appendQueryParamsToPath } from '@app/utils/link.utils';
import { TrackLinkFunction, TrackLinkModule } from '@app/utils/track-link.directive';

export enum OmguiButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  link = 'link',
}

export enum OmguiButtonSize {
  small = 'sm',
  large = 'lg',
  fillParent = 'fill-parent',
}

export class ConflictingInputError extends Error {
  name = 'ConflictingInputError';
  constructor(klass: Function, inputNames: string[]) {
    super(`Conflicting Inputs ${inputNames.join(', ')} on ${klass.name}`);
    // Makes Typescript + Jest happy, see https://github.com/facebook/jest/issues/8279#issuecomment-539775425
    Object.setPrototypeOf(this, ConflictingInputError.prototype);
  }
}

export enum OmguiButtonType {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

@Component({
  selector: 'omgui-button',
  templateUrl: './omgui-button.component.html',
  styleUrls: ['./omgui-button.component.scss'],
})
export class OmguiButtonComponent implements OnInit, OnChanges {
  @Input() variant: OmguiButtonVariant = OmguiButtonVariant.primary;
  @Input() size: OmguiButtonSize = OmguiButtonSize.large;
  @Input() disabled = false;
  @Input() newTab = false;
  @Input() externalLink: string;
  @Input() internalLink: string;
  @Input() trackLink: TrackLinkFunction;
  @Input() type: OmguiButtonType = OmguiButtonType.submit;
  @Input() queryParams: Params = {};
  @Input() customButtonClass: Record<string, boolean> | string;

  @Output() buttonClick = new EventEmitter();

  @ViewChild('buttonRef') buttonRef: ElementRef;

  buttonClass: Record<string, boolean>;

  /**
   * Sets the focus on the button.
   */
  focus(): void {
    this.buttonRef.nativeElement.focus();
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    if (this.externalLink && this.internalLink) {
      throw new ConflictingInputError(OmguiButtonComponent, ['externalLink', 'internalLink']);
    }

    this.buttonClass = this.getButtonClass();
    this.externalLink = appendQueryParamsToPath(this.externalLink, this.queryParams);
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variant || changes.size || changes.customButtonClass) {
      this.buttonClass = this.getButtonClass();
    }

    if (changes.externalLink || changes.queryParams) {
      this.externalLink = appendQueryParamsToPath(this.externalLink, this.queryParams);
    }
  }

  /**
   * @ignore
   */
  private getButtonClass(): Record<string, boolean> {
    const buttonClass = {
      'rounded-pill': true,
      'btn-primary': this.variant === OmguiButtonVariant.primary,
      'btn-outline-primary': this.variant === OmguiButtonVariant.secondary,
      'btn-link': this.variant === OmguiButtonVariant.link,
      'btn-sm': this.size === OmguiButtonSize.small,
      'w-100': this.size === OmguiButtonSize.fillParent,
      btn: true,
    };

    if (typeof this.customButtonClass === 'string') {
      buttonClass[this.customButtonClass] = true;
    }

    if (typeof this.customButtonClass === 'object') {
      Object.assign(buttonClass, buttonClass, this.customButtonClass);
    }

    return buttonClass;
  }
}

@NgModule({
  declarations: [OmguiButtonComponent],
  imports: [CommonModule, RouterModule, TrackLinkModule],
  exports: [OmguiButtonComponent],
})
export class OmguiButtonModule {}
