import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { MembershipResult_membership_plan_B2bPlan } from '@app/core/__generated__/MembershipResult';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';
import { ServiceAreaService } from '@app/shared/service-area.service';
import { User } from '@app/shared/user';

import { EnterpriseRegistrationAnalyticsService } from '../../registration/enterprise/enterprise-registration-analytics.service';
import { MembershipType } from '../../registration/enterprise/membership-type';
import { RoutingGroupOption } from '../routing-group/routing-group-option.interface';

@Component({
  selector: 'om-direct-signup',
  templateUrl: './direct-signup.component.html',
  styleUrls: ['./direct-signup.component.scss'],
})
export class DirectSignupComponent implements OnInit, OnDestroy {
  @ViewChild('hasNoUnregisteredDependents', { static: true }) hasNoUnregisteredDependents: TemplateRef<any>;

  @Output() membershipTypeSelected = new EventEmitter<MembershipType>();

  private destroy$ = new Subject<void>();

  user: User;
  formControl: UntypedFormControl = new UntypedFormControl('');
  modalRef: NgbModalRef;
  CHILD_SUBHEADER_PEDS_AVAILABLE = 'Register your dependent under 18';
  CHILD_SUBHEADER_PEDS_NOT_AVAILABLE = 'Register your dependent age 14+';

  adultDependentOption: RoutingGroupOption<MembershipType> = {
    iconUrl: '/assets/images/spouse-icon.png',
    header: 'Adult membership',
    subheader: 'Register your spouse or dependents 18+',
    value: MembershipType.SPOUSE,
  };

  pedsDisabledOption: RoutingGroupOption<MembershipType> = {
    iconUrl: '/assets/images/kids-icon.png',
    header: 'Kids membership',
    subheader: this.CHILD_SUBHEADER_PEDS_NOT_AVAILABLE,
    value: MembershipType.KIDS,
  };

  pedsEnabledOption: RoutingGroupOption<MembershipType> = {
    iconUrl: '/assets/images/kids-icon.png',
    header: 'Kids membership',
    subheader: this.CHILD_SUBHEADER_PEDS_AVAILABLE,
    value: MembershipType.KIDS,
  };

  options$ = new BehaviorSubject<RoutingGroupOption<MembershipType>[]>([]);

  constructor(
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private router: Router,
    private serviceAreaService: ServiceAreaService,
    private membershipService: MembershipService,
    private userService: UserService,
    private membershipGraphQL: MembershipGraphQL,
    public modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.enterpriseRegistrationAnalyticsService.directSignupSelectionPageViewed();

    this.initUser().subscribe();
  }

  getMembership() {
    return this.membershipService.membership$;
  }

  getUser() {
    return this.userService.user$;
  }

  getPedsAvailableInServiceArea() {
    return this.getUser().pipe(
      switchMap((user: User) => {
        this.serviceAreaService.getPediatricsAvailability(user.serviceArea);
        return this.serviceAreaService.pediatricsAvailableInServiceArea$;
      }),
    );
  }

  shouldVerifyDependentInfo(): Observable<boolean> {
    return this.membershipGraphQL.fetch().pipe(
      map(membershipResult => {
        const plan = membershipResult.data.membership.plan as MembershipResult_membership_plan_B2bPlan;
        if (!plan.company) {
          return false;
        }

        return plan.company.verifyDependentInfo;
      }),
    );
  }

  initUser(): Observable<any> {
    this.userService.getUser();
    this.membershipService.getMembership();
    return combineLatest([
      this.getUser(),
      this.shouldVerifyDependentInfo(),
      this.getMembership(),
      this.getPedsAvailableInServiceArea(),
    ]).pipe(
      take(1),
      tap(([user, shouldVerifyDependentInfo, membership, pedsAvailableInServiceArea]) => {
        if (
          user.whitelistedEmployee &&
          shouldVerifyDependentInfo &&
          !user.hasUnregisteredDependents &&
          !membership.enterprisePedsRegistrationEnabled
        ) {
          this.openModal();
        }
        if (
          !pedsAvailableInServiceArea ||
          (!membership.enterprisePedsRegistrationEnabled && user.whitelistedEmployee)
        ) {
          this.options$.next([this.adultDependentOption, this.pedsDisabledOption]);
        } else {
          this.options$.next([this.adultDependentOption, this.pedsEnabledOption]);
        }
      }),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  redirectToHome() {
    this.modalRef.close();
    this.router.navigate(['/']);
  }

  openModal() {
    this.modalRef = this.modalService.open(this.hasNoUnregisteredDependents, {
      backdrop: 'static',
      keyboard: false,
    });
  }
}
