import { Injectable } from '@angular/core';
import { Router, UrlCreationOptions, UrlTree } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { LinksService } from '@app/core/links.service';
import {
  HORNBILL_ANALYTICS_CLAIM_CODE_PARAM_KEY,
  HORNBILL_ANALYTICS_ERROR_SOURCE_PARAM_KEY,
} from '@app/shared/hornbill-test-params';

import { ValidateClaimCodeQuery } from './__generated__/prepaid-enrollment-validate-claim-code-graphql.service.types';
import { PrepaidEnrollmentValidateClaimCodeGraphQL } from './prepaid-enrollment-validate-claim-code-graphql.service';
import {
  MembershipGroupInviteValidationErrors,
  RegistrationErrorCodes,
} from './registration-error/registration-error.component';

export interface PrepaidEnrollmentErrorRoutingProperties {
  error: ClaimCodeValidationError;
  source: string;
  claimCode?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PrepaidEnrollmentService {
  constructor(
    private router: Router,
    private linksService: LinksService,
    private launchDarklyService: LaunchDarklyService,
    private validateClaimCodeGraphQL: PrepaidEnrollmentValidateClaimCodeGraphQL,
  ) {}

  isValid$(claimCode: string): Observable<boolean> {
    return this.validateClaimCode$(claimCode).pipe(
      map(claimCodeResponse => !!claimCodeResponse?.validateClaimCode?.isValidClaimCode),
    );
  }

  validateClaimCode$(claimCode: string): Observable<ValidateClaimCodeQuery> {
    return this.featureEnabled$().pipe(
      switchMap(flagEnabled => {
        if (!flagEnabled) {
          throw new ClaimCodeValidationError(
            `${FeatureFlags.HORNBILL_ENROLLMENT_P0_ENABLED} feature flag is not enabled`,
          );
        }
        if (claimCode === null) {
          throw new ClaimCodeValidationError('ClaimCode cannot be null');
        }
        return this.validateClaimCodeGraphQL.fetch({ claimCode }).pipe(
          map(({ data }) => {
            if (data?.validateClaimCode?.error) {
              throw data.validateClaimCode.error;
            }
            return data;
          }),
        );
      }),
    );
  }

  getErrorRoute$(props: PrepaidEnrollmentErrorRoutingProperties): Observable<UrlTree> {
    return this.analyticsFlagEnabled$().pipe(
      switchMap(analyticsFlagEnabled => {
        let urlTreeOptions: UrlCreationOptions;
        switch (props.error.code) {
          case MembershipGroupInviteValidationErrors.ClaimedCode:
            urlTreeOptions = { queryParams: { errorCode: RegistrationErrorCodes.PrepaidEnrollmentCodeClaimed } };
            break;
          case MembershipGroupInviteValidationErrors.InvalidCode:
          case MembershipGroupInviteValidationErrors.RevokedCode:
          case MembershipGroupInviteValidationErrors.RejectedCode:
            urlTreeOptions = { queryParams: { errorCode: RegistrationErrorCodes.PrepaidEnrollmentInvalidCode } };
            break;
          default:
            urlTreeOptions = { queryParams: { errorCode: RegistrationErrorCodes.PrepaidEnrollmentDefault } };
        }
        if (analyticsFlagEnabled) {
          urlTreeOptions.queryParams[HORNBILL_ANALYTICS_ERROR_SOURCE_PARAM_KEY] = props.source;
          urlTreeOptions.queryParams[HORNBILL_ANALYTICS_CLAIM_CODE_PARAM_KEY] = props.claimCode;
        }
        return observableOf(this.router.createUrlTree([this.linksService.registrationError], urlTreeOptions));
      }),
    );
  }

  private featureEnabled$(): Observable<boolean> {
    return this.launchDarklyService.featureFlag$(FeatureFlags.HORNBILL_ENROLLMENT_P0_ENABLED, false);
  }

  private analyticsFlagEnabled$(): Observable<boolean> {
    return this.launchDarklyService.featureFlag$(FeatureFlags.HORNBILL_ERROR_PAGE_ANALYTICS_ENABLED, false);
  }
}

export class ClaimCodeValidationError extends Error {
  readonly code: string;

  constructor(code: string) {
    super(`ClaimCodeValidationError: ${code}`);
    this.code = code;
  }
}
