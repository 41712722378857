import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, startWith, take, tap } from 'rxjs/operators';

import {
  MembershipResult,
  MembershipResult_membership_plan_B2bPlan as B2bPlan,
} from '@app/core/__generated__/MembershipResult';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { Membership, MembershipPlan } from '@app/core/membership';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { MembershipService } from '@app/core/membership.service';
import { TargetUserService } from '@app/core/target-user.service';
import { UserService } from '@app/core/user.service';

import { ProgramEnrollmentGraphQL } from '../program-enrollment-graphql.service';
import { User } from '../user';
import { PrincipalUser } from './__generated__/principal-user-graphql.service.types';
import { TargetUser } from './__generated__/valid-target-users-graphql.service.types';
import { NavbarAnalyticsService } from './navbar-analytics.service';
import { PrincipalUserGraphQL } from './principal-user-graphql.service';
import { ValidTargetUsersGraphQL } from './valid-target-users-graphql.service';

@Component({
  selector: 'om-navbar-container',
  templateUrl: 'navbar-container.component.html',
})
export class NavbarContainerComponent implements OnInit {
  static NAVBAR_HEIGHT = 84;
  static MINDSET_PROGRAM_NAME = 'mindset+';

  currentUser$: Observable<User>;
  principalUser$: Observable<PrincipalUser>;
  targetUsers$: Observable<(TargetUser | null)[] | null>;

  canBookVisit$: Observable<boolean>;
  canRegisterKid$: Observable<boolean>;
  enrolledInMindsetPlus$: Observable<boolean>;
  inactiveMembership$: Observable<boolean>;
  inviteCta$: Observable<string>;
  displayInviteCta$: Observable<Boolean>;

  dataReady$: Observable<boolean>;

  private membership$: Observable<Membership>;

  constructor(
    private apollo: Apollo,
    private featureFlagSelectors: FeatureFlagSelectors,
    private membershipGraphQL: MembershipGraphQL,
    private membershipService: MembershipService,
    private navbarAnalyticsService: NavbarAnalyticsService,
    private principalUserGraphQL: PrincipalUserGraphQL,
    private programEnrollmentGraphQL: ProgramEnrollmentGraphQL,
    private router: Router,
    private targetUserService: TargetUserService,
    private userService: UserService,
    private validTargetUsersGraphQL: ValidTargetUsersGraphQL,
  ) {}

  ngOnInit() {
    this.currentUser$ = this.userService.getUser().pipe(shareReplay(1));
    this.membership$ = this.membershipService.getMembership().pipe(shareReplay(1));

    this.canBookVisit$ = this.membership$.pipe(map(membership => membership.canBookVisit));
    this.canRegisterKid$ = this.initCanRegisterKid$();
    this.enrolledInMindsetPlus$ = this.initEnrolledInMindsetPlus$();
    this.inactiveMembership$ = this.membership$.pipe(map(membership => !membership.isActive));
    this.inviteCta$ = this.initInviteCta$(this.currentUser$);

    this.principalUser$ = this.principalUserGraphQL.principalUser$().pipe(
      shareReplay(1),
      tap(principalUser => this.navbarAnalyticsService.setPrincipalUser(principalUser)),
    );
    this.targetUsers$ = this.validTargetUsersGraphQL.targetUsers$().pipe(shareReplay(1));

    this.dataReady$ = combineLatest([
      this.currentUser$,
      this.membership$,
      this.principalUser$,
      this.targetUsers$,
      this.canBookVisit$,
      this.canRegisterKid$,
      this.enrolledInMindsetPlus$,
      this.inactiveMembership$,
      this.inviteCta$,
    ]).pipe(
      take(1),
      map(() => true),
    );
    this.displayInviteCta$ = this.userService.displayReferralCode();
  }

  resetTargetUser() {
    this.navbarAnalyticsService.trackSwitchAccountClicked();
    this.targetUserService.resetTargetUser();

    this.navbarAnalyticsService.resetMixpanelId();
    this.apollo.client.resetStore();

    combineLatest([this.userService.getUser(true), this.membershipService.getMembership(true)])
      .pipe(take(1))
      .subscribe({
        next: () => this.router.navigate(['/']),
      });
  }

  setTargetUser(targetUser: TargetUser) {
    this.navbarAnalyticsService.trackSwitchAccountClicked(targetUser);
    this.targetUserService.setTargetUser(targetUser);

    this.navbarAnalyticsService.resetMixpanelId();
    this.apollo.client.resetStore();

    combineLatest([this.userService.getUser(true), this.membershipService.getMembership(true)])
      .pipe(take(1))
      .subscribe({
        next: () => this.router.navigate(['/']),
      });
  }

  private initCanRegisterKid$(): Observable<boolean> {
    const isB2bCoreMember$ = this.membershipGraphQL.fetch().pipe(
      map((result: ApolloQueryResult<MembershipResult>) => {
        const { plan, planType } = result.data.membership;
        return (
          !(plan as B2bPlan)?.company?.includesDependent &&
          planType !== MembershipPlan.SELF_PAID &&
          planType !== MembershipPlan.SELF_PAID_AMAZON
        );
      }),
    );

    const canInvitePediatricConsumerMembers$ = combineLatest([this.membership$, this.currentUser$]).pipe(
      map(
        ([membership, user]) =>
          membership.omMembershipType === 'Consumer' && !user.isPediatric() && !membership.isAmazonPaid(),
      ),
    );

    return combineLatest([isB2bCoreMember$, canInvitePediatricConsumerMembers$]).pipe(
      map(([isB2bCore, isAdultConsumer]) => isB2bCore || isAdultConsumer),
      shareReplay(1),
    );
  }

  private initEnrolledInMindsetPlus$(): Observable<boolean> {
    return combineLatest([
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.BHX_MEMBER_PROGRAM_EXPERIENCE, false),
      this.programEnrollmentGraphQL.enrollmentForProgram$(NavbarContainerComponent.MINDSET_PROGRAM_NAME),
    ]).pipe(
      map(([flagEnabled, programEnrollment]) => flagEnabled && !!programEnrollment),
      shareReplay(1),
    );
  }

  private initInviteCta$(user$: Observable<User>): Observable<string> {
    return user$.pipe(
      map(user => {
        if (user.referralDiscount) {
          return `Give ${user.referralDiscount.getDiscountString()} off`;
        }
      }),
      startWith('Invite Friends'),
      shareReplay(1),
    );
  }
}
