import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import debounce from 'lodash-es/debounce';
import {
  BehaviorSubject,
  iif,
  Observable,
  of as observableOf,
  Subject,
  throwError as observableThrowError,
  combineLatest,
  take,
} from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';
import { ApiService } from '@app/core/api.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { Membership } from '@app/core/membership';
import { MembershipEvent } from '@app/core/membership-event';
import { UserService } from '@app/core/user.service';
import { RealtimeCommunicationService } from '@app/shared/realtime-communication.service';
import { User } from '@app/shared/user';

const PUSHER_MEMBERSHIP_EVENT_NAME = 'membership_processing_done';

export interface ApiV2Response {
  b2b_company?: {
    id: number;
    display_name: string;
    return_to_work: boolean;
    return_to_work_client?: {
      show_homepage_survey_card: boolean;
    };
  };
  can_book_visit: boolean;
  can_cancel: boolean;
  can_convert_to_direct: boolean;
  can_reactivate: boolean;
  can_update_b2b_code: boolean;
  can_update_billing: boolean;
  is_active: boolean;
  credit_card?: {
    brand: string;
    last4: string;
    exp_month: string;
    exp_year: string;
  };
  enterprise_peds_registration_enabled: boolean;
  expiration_date: {
    date: string;
    action: string;
  };
  patient_status: string;
  plan_id: number;
  plan_type: string;
  release_required_for_rtw_screener?: boolean;
  renewal_plan?: {
    amount: number;
  };
  previous_enterprise_membership?: {
    b2b_company: {
      id: number;
      name?: string;
      display_name?: string;
    };
  };
  status: string;
  valid_until: string;
  om_membership_type: string;
  is_enterprise_transitional?: boolean;
  trial_until: string | null;
  deactivate_at_trial_end: boolean;
  consumer_promo_discount?: object;
  events: MembershipEvent[];
  is_paid_with_afterpay: boolean;
  is_virtual: boolean;
  group_subscription_details?: {
    group_subscription_id: number;
    membership_manager: boolean;
    manager_name: string;
    seat_status: string;
    stopped_by: string;
    billing_cycle: string;
    number_of_seats: number;
    number_of_occupied_seats: number;
  };
  drop_off_claim_code?: string;
}

interface CreateOrRenewConsumerOrAmazonMembershipRequest {
  paymentMethodId?: string;
  stripeTokenId?: string;
  coupon?: string;
  claimCode?: string;
  giftCode?: string;
  paymentIntentId?: string;
  workflow?: string;
  membershipAction?: MembershipAction;
}

export interface CreateOrRenewConsumerOrAmazonMembershipResponse {
  error: string;
  membership_id: number;
  success: boolean;
}

export interface ChargeMembershipResponse {
  error: string;
  success: any;
  message: string;
}

export interface DeactivateRecurringBillingResponse extends AsyncChannelResponse {
  membership_id: number;
  response: string;
}

export interface ReactivateRecurringBillingResponse extends AsyncChannelResponse {
  success: boolean;
  response: string;
}

export interface RecurringBillingPusherResponse {
  error: string;
  success: boolean;
}

export interface AsyncChannelResponse {
  channel_name: string;
  event_name: string;
}

export enum MembershipAction {
  Conversion = 'conversion',
  New = 'new',
}

@Injectable()
export class MembershipService {
  private _membership$ = new BehaviorSubject<Membership>(null);
  private readonly _debouncedGetMembership: () => {};

  /** @deprecated There's no guarantee that membership$ will emit something, which can result in subscribers not
   * receiving data at all. Subscribe to getMembership() instead, which makes an API call for membership data if
   * needed, so subscribers are guaranteed to receive something.
   * */
  readonly membership$: Observable<Membership>;

  constructor(
    private apiService: ApiService,
    private realtimeCommunicationService: RealtimeCommunicationService,
    private analytics: AnalyticsService,
    private featureFlagSelectors: FeatureFlagSelectors,
    private userService: UserService,
  ) {
    this.membership$ = this._membership$.asObservable().pipe(
      filter(membership => membership != null),
      tap((membership: Membership) => this.analytics.updateMembershipProperties(membership)),
    );

    this._debouncedGetMembership = debounce(this._getMembership.bind(this), 1000, {
      leading: true,
    });
  }

  getMembership(force = false) {
    if (force) {
      this._getMembership();
    } else if (this._membership$.getValue() == null) {
      this._debouncedGetMembership();
    }

    return this.membership$;
  }

  // this renews consumer membership or creates a new consumer membership if expired or if member was b2b
  createOrRenewConsumerOrAmazonMembership({
    paymentMethodId,
    stripeTokenId,
    coupon,
    claimCode,
    giftCode,
    paymentIntentId,
    workflow,
    membershipAction,
  }: CreateOrRenewConsumerOrAmazonMembershipRequest): Observable<CreateOrRenewConsumerOrAmazonMembershipResponse> {
    const params: Record<string, any> = {
      stripe_token: stripeTokenId,
      payment_method_id: paymentMethodId,
      payment_intent_id: paymentIntentId,
      callback_type: 'pusher',
    };

    if (coupon) {
      params.stripe_coupon_id = coupon;
    }

    if (claimCode) {
      params.claim_code = claimCode;
    }

    if (giftCode) {
      params.gift_code = giftCode;
    }

    if (membershipAction) {
      params.membership_action = membershipAction;
    }

    if (workflow) {
      params.workflow = workflow;
    }

    const updateMembership = () => this.apiService.patch('/api/v2/patient/membership', params);

    return this.handlePusherResponse(updateMembership, this.getUserId$());
  }

  private handlePusherResponse(
    pusherRequest: () => Observable<Object>,
    userId$: Observable<number>,
  ): Observable<CreateOrRenewConsumerOrAmazonMembershipResponse> {
    const pusherNotificationRequest$: Observable<CreateOrRenewConsumerOrAmazonMembershipResponse> = pusherRequest().pipe(
      switchMap(({ channel_name, event_name }: AsyncChannelResponse) =>
        this.realtimeCommunicationService.getResponse<CreateOrRenewConsumerOrAmazonMembershipResponse>(
          channel_name,
          event_name,
        ),
      ),
    );

    const pusherNotificationRequestWithRTCRefactor$: Observable<CreateOrRenewConsumerOrAmazonMembershipResponse> = userId$.pipe(
      take(1),
      switchMap(userId => {
        const [response$, subscribed$] = this.realtimeCommunicationService.getResponseAndSubscription<
          CreateOrRenewConsumerOrAmazonMembershipResponse
        >(`pt-${userId}`, PUSHER_MEMBERSHIP_EVENT_NAME);
        return subscribed$.pipe(
          concatMap(() => pusherRequest()),
          switchMap(() => response$),
        );
      }),
    );

    const appSyncNotificationRequest$: Observable<CreateOrRenewConsumerOrAmazonMembershipResponse> = pusherRequest().pipe(
      switchMap(({ channel_name }: AsyncChannelResponse) =>
        this.realtimeCommunicationService.getAppSyncResponse<CreateOrRenewConsumerOrAmazonMembershipResponse>(
          'Membership',
          'UPDATE',
          channel_name,
        ),
      ),
    );

    return combineLatest([
      this.featureFlagSelectors.getFeatureFlag<boolean>(FeatureFlags.CONSUMER_REGISTRATION_APP_SYNC_COMMUNICATION),
      this.featureFlagSelectors.getFeatureFlag<boolean>(
        FeatureFlags.CONSUMER_REGISTRATION_MEMBERSHIP_SERVICE_PUSHER_REFACTOR,
      ),
    ]).pipe(
      mergeMap(([appSyncEnabled, rtcRefactorEnabled]) =>
        iif(
          () => appSyncEnabled === true,
          appSyncNotificationRequest$,
          iif(() => rtcRefactorEnabled === true, pusherNotificationRequestWithRTCRefactor$, pusherNotificationRequest$),
        ),
      ),
      this.mapCreateOrRenewMembershipEvent(),
      tap(() => {
        this.getMembership(true);
      }),
    );
  }

  private mapCreateOrRenewMembershipEvent() {
    return map((event: CreateOrRenewConsumerOrAmazonMembershipResponse) => {
      if (event.error) {
        throw event.error;
      } else {
        return event;
      }
    });
  }

  // this updates a pediatrics amazon membership
  updatePediatricAmazonMembership$(
    patient: Pick<User, 'id' | 'membershipId'>,
    prepaidClaimCode: string,
    membershipAction?: MembershipAction,
  ): Observable<CreateOrRenewConsumerOrAmazonMembershipResponse> {
    const params: Record<string, any> = {
      membership_id: patient.membershipId,
      claim_code: prepaidClaimCode,
    };

    if (membershipAction) {
      params.membership_action = membershipAction;
    }

    const updatePediatricMembership = () => this.apiService.patch('/api/v2/patient/pediatric/membership', params);

    return this.handlePusherResponse(updatePediatricMembership, observableOf(patient.id));
  }

  private getUserId$(): Observable<number> {
    return this.userService.getUser().pipe(
      take(1),
      map(user => user.id),
    );
  }

  chargeMembership(
    membershipId: number,
    {
      stripeTokenId,
      coupon,
      code,
      giftCode,
    }: { stripeTokenId?: string; coupon?: string; code?: string; giftCode?: string } = {},
  ): Subject<ChargeMembershipResponse> {
    const stripeResponse = new Subject<ChargeMembershipResponse>();
    const params = {
      stripe_token: stripeTokenId,
      membership_id: membershipId,
      coupon,
      code,
      gift_code: giftCode,
    };

    const pusherNotificationRequest$ = this.apiService
      .post('/api/v2/patient/pediatric/membership/charge', params)
      .pipe(
        switchMap(({ channel_name, event_name }: AsyncChannelResponse) =>
          this.realtimeCommunicationService.getResponse<ChargeMembershipResponse>(channel_name, event_name),
        ),
      );
    const appSyncNotificationRequest$ = this.apiService
      .post('/api/v2/patient/pediatric/membership/charge', params)
      .pipe(
        switchMap(({ channel_name }: AsyncChannelResponse) =>
          this.realtimeCommunicationService.getAppSyncResponse<ChargeMembershipResponse>(
            'Membership',
            'UPDATE',
            channel_name,
          ),
        ),
      );
    this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.CONSUMER_REGISTRATION_APP_SYNC_PEDIATRIC_FLOW)
      .pipe(mergeMap(enabled => iif(() => enabled === true, appSyncNotificationRequest$, pusherNotificationRequest$)))
      .subscribe({
        next: this.processSuccessChargeMembershipResponse(stripeResponse),
        error: error => stripeResponse.error(error),
      });

    return stripeResponse;
  }

  deactivateMembership(
    useAppSyncDeactivateFeatureFlag: boolean,
    deactivateReasonId: number,
    deactivateReasonNotes?: string,
  ): Observable<any> {
    return this.apiService
      .post('/api/v2/patient/membership/deactivate_recurring_billing', {
        deactivate_recurring_billing_reason: deactivateReasonId,
        deactivate_recurring_billing_notes: deactivateReasonNotes,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err && err.error && err.error.response) {
            return observableThrowError(err.error.response);
          }

          return observableThrowError(err);
        }),
        switchMap(({ channel_name, event_name }: DeactivateRecurringBillingResponse) => {
          if (channel_name) {
            if (useAppSyncDeactivateFeatureFlag) {
              return this.realtimeCommunicationService.getAppSyncResponse<ChargeMembershipResponse>(
                'Membership',
                'DELETE',
                channel_name,
              );
            } else {
              return this.realtimeCommunicationService.getResponse<RecurringBillingPusherResponse>(
                channel_name,
                event_name,
              );
            }
          } else {
            // The "new Stripe workflow" feature flag requires Pusher; legacy workflow does not include it
            // TODO: remove in https://jira.onemedical.com/browse/CGR-564
            return observableOf({ success: true });
          }
        }),
        map((event: RecurringBillingPusherResponse) => {
          if (!event.success) {
            throw event.error;
          }

          return event;
        }),
      );
  }

  reactivateMembership(): Observable<any> {
    return this.apiService.post('/api/v2/patient/membership/reactivate_recurring_billing', {}).pipe(
      switchMap(({ success, response, channel_name, event_name }: ReactivateRecurringBillingResponse) => {
        if (!success) {
          return observableThrowError(response);
        }

        if (channel_name) {
          return this.realtimeCommunicationService.getResponse<RecurringBillingPusherResponse>(
            channel_name,
            event_name,
          );
        } else {
          // The "new Stripe workflow" feature flag requires Pusher; legacy workflow does not include it
          // TODO: remove in https://jira.onemedical.com/browse/CGR-564
          return observableOf({ success });
        }
      }),
      map((event: RecurringBillingPusherResponse) => {
        if (!event.success) {
          throw event.error;
        }

        return event;
      }),
    );
  }

  convertTrialMembership(): Observable<{ success: boolean; response: string }> {
    return this.apiService.post('/api/v2/patient/membership/convert_trial_membership', {});
  }

  getNewMembershipPrice(serviceAreaCode: string, discountCode?: string): Observable<{ amount: number }> {
    let url = `/pt/registration/membership_price?new_reg_plan=true&service_area_code=${serviceAreaCode}`;
    if (discountCode) {
      url = url + `&discount_code=${discountCode}`;
    }
    return this.apiService.get(url) as Observable<{ amount: number }>;
  }

  private processSuccessChargeMembershipResponse(stripeResponse: Subject<ChargeMembershipResponse>) {
    return (response: ChargeMembershipResponse) => {
      if (response.success) {
        stripeResponse.next(response);
      } else {
        stripeResponse.error(response.error);
      }
      stripeResponse.complete();
    };
  }

  private _getMembership(ignoreUnauthorized = false) {
    const request = this.apiService
      .get('/api/v2/patient/membership', ignoreUnauthorized)
      .pipe(map((membership: ApiV2Response) => Membership.fromApiV2(membership)));
    request.subscribe(membership => {
      this._membership$.next(membership);
    });
    return request;
  }

  getMembershipWithRequest() {
    return this._getMembership(true);
  }
}
