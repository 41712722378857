import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { LinksService } from '@app/core/links.service';
import { OmguiButtonModule } from '@app/omgui/omgui-button/omgui-button.component';
import { OmguiExternalLinkIconModule } from '@app/omgui/omgui-external-link-icon/omgui-external-link-icon.component';
import { ERROR_STATE_DIRECTIVES } from '@app/shared/error-state/error-state.component';
import { HORNBILL_MANAGE_ON_AMAZON_URL } from '@app/shared/hornbill-test-params';
import { SimpleNavbarComponent } from '@app/shared/simple-navbar/simple-navbar.component';

import { RegistrationAnalyticsService } from '..';

export enum RegistrationErrorCodes {
  PrepaidEnrollmentDefault = 'prepaid_enrollment_default',
  PrepaidEnrollmentInvalidCode = 'prepaid_enrollment_invalid_code',
  PrepaidEnrollmentCodeClaimed = 'prepaid_enrollment_claimed_code',
}

export enum MembershipGroupInviteValidationErrors {
  NotFound = 'not_found',
  InvalidCode = 'invalid_code',
  RevokedCode = 'revoked_code',
  RejectedCode = 'rejected_code',
  ClaimedCode = 'claimed_code',
}

@Component({
  selector: 'om-registration-error',
  templateUrl: './registration-error.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SimpleNavbarComponent,
    OmguiButtonModule,
    OmguiExternalLinkIconModule,
    ...ERROR_STATE_DIRECTIVES,
  ],
})
export class RegistrationErrorComponent implements OnInit {
  errorCode: RegistrationErrorCodes = RegistrationErrorCodes.PrepaidEnrollmentDefault;
  readonly RegistrationErrorCodes = RegistrationErrorCodes;
  readonly HORNBILL_MANAGE_ON_AMAZON_URL = HORNBILL_MANAGE_ON_AMAZON_URL;

  constructor(
    readonly links: LinksService,
    private activatedRoute: ActivatedRoute,
    private registrationAnalyticsService: RegistrationAnalyticsService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    const routeErrorCode = this.activatedRoute.snapshot.queryParams['errorCode'];
    const registrationErrorCodes = [
      RegistrationErrorCodes.PrepaidEnrollmentInvalidCode,
      RegistrationErrorCodes.PrepaidEnrollmentCodeClaimed,
    ];

    if (registrationErrorCodes.includes(routeErrorCode)) {
      this.errorCode = routeErrorCode;
    }
    this.featureEnabled$().subscribe({
      next: (flagEnabled: true) => {
        if (flagEnabled) {
          const routeClaimCode = this.activatedRoute.snapshot.queryParams['claimCode'];
          const routeSource = this.activatedRoute.snapshot.queryParams['errorSource'];

          this.registrationAnalyticsService.trackPrepaidEnrollmentDefaultErrorPageView(
            this.errorCode,
            routeClaimCode,
            routeSource,
          );
        }
      },
    });
  }

  private featureEnabled$(): Observable<boolean> {
    return this.launchDarklyService.featureFlag$(FeatureFlags.HORNBILL_ERROR_PAGE_ANALYTICS_ENABLED, false);
  }
}
